/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { Dimensions, StyleSheet, View, Image, Text} from 'react-native';

const {width, height} = Dimensions.get('screen');

export default class ScreenHint extends React.PureComponent {

    /**
     *
     */
    render() {
        return (
            <View style={{flex: 1, flexDirection: 'row'}}>
                <Image style={styles.background} source={require('../../assets/images/bg-screen-hint.png')}/>
                <Text style={styles.text}>{this.props.text}</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    text: {
        position: 'absolute',
        top: height*.025,

        fontFamily: 'YuMincho',
        fontSize: 14,
        color: '#70615A',

        textAlign: 'center',
        width: width*.9,
        height: height*.05
    },
    background: {
        width: width*.9,
        height: (width*.9)/5.94,

        resizeMode: 'stretch',
    }
});