/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { Dimensions, StyleSheet, View, Image, Text, TouchableOpacity, Linking} from 'react-native';
import {NavigationActions} from "react-navigation";
import Entities from "../utils/Entities";

import Styles from "../constants/Styles";
import {StackActions} from "react-navigation";

const {width, height} = Dimensions.get('window');

export default class ActionButton extends React.PureComponent {

    /**
     *
     */
    render() {

        return (
            <View style={[{flex: 1, flexDirection: 'row', marginBottom:0}, Styles.common.Center]}>
                <TouchableOpacity style={styles.controls} activeOpacity={.8} onPress={() => {
                    if(!Entities.isEmpty(this.props.url)){
                        Linking.openURL(this.props.url);
                    }
                    if(!Entities.isEmpty(this.props.action)){
                        if(this.props.action === 'Top'){
                            this.props.navigation.dispatch(StackActions.popToTop({immediate: true}));
                        }
                        this.navigateToScreen(this.props.action);
                    }
                }}>
                    {this.renderBackground()}
                    {this.renderTitle()}
                </TouchableOpacity>
            </View>
        );
    }

    renderBackground(){
        if(Entities.isDefined(this.props.light)){
            return (
                <Image style={styles.button} source={require('../../assets/images/btn-action-light.png')}/>
            );
        }
        return (
            <Image style={styles.button} source={require('../../assets/images/btn-action.png')}/>
        );
    }

    renderTitle(){
        if(Entities.isDefined(this.props.light)){
            return (
                <Text style={[styles.text, {color: '#70615A'}]}>{this.props.title}</Text>
            );
        }
        return (
            <Text style={styles.text}>{this.props.title}</Text>
        );
    }

    /**
     *
     */
    navigateToScreen(name){
        const navigationAction = NavigationActions.navigate({
            routeName: name,
            params: {
                data: this.props.data
            }
        });

        if(!Entities.isEmpty(this.props.navigation)){
            this.props.navigation.dispatch(navigationAction);
        }
    }
}

const styles = StyleSheet.create({
    button: {
        width: width*.882,
        height: width*.882*.168
    },
    text: {
        position: 'absolute',
        top: width*.4*.075,

        fontFamily: 'YuMincho',
        fontSize: width*.4*.33/4,
        color: '#fff',

        textAlign: 'center',
        width: width*.9,
        height: height*.05
    },
    controls: {
        width: width*.882,
        height: width*.882*.168
    }
});