/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

import ErrorCodes from './ErrorCodes';
import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

const CardsAPI = {

    cards: null,

    /**
     *
     * @param code
     * @returns {{success: boolean}}
     */
    get(no){
        var result = {success: true, data: null};

        if(Entities.isEmpty(this.cards)){
            this.init();
        }

        if(Entities.isEmpty(no)){
            if(this.cards.length != 0){
                result.data = this.cards[0];
            }
        }else {
            result.data = this.cards.find(card => card.no === no);
        }

        if(Entities.isEmpty(result.data)){
            result.success = false;
            result.error = ErrorCodes.ERROR_CARD_NOT_FOUND;
        }
        return result;
    },

    saveLocalStorage() {
        // this.saveCards();
    },

    loadLocalStorage() {
        // this.cards =  this.loadCards();
    },

    async saveCards() {
        try {
            await AsyncStorage.setItem('cards', JSON.stringify(this.cards));
        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
    },

    async loadCards() {
        let guidebook = '';
        try {
            guidebook = await AsyncStorage.getItem('cards') || 'none';
            return JSON.parse(guidebook);

        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
        return null;
    },

    init(){
        this.loadLocalStorage();

        if (Entities.isEmpty(this.cards)){

            this.cards = [
                {
                    no: 1,
                    title: '風流人',
                    image: require('../../assets/cards/01.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は、自分のやりたいことや信念を貫き通すと良い１日。周りへの気遣いや忖度は一度忘れて、自分の思いを誠意を持って伝えるべき人へ伝えてみたり、やりたいと思ったことに思い切ってチャレンジしてみましょう！貴方の芯の強さが周りに良い影響を与え、人の心を動かすこともありそうです。自信を持って堂々と自分らしさを発揮してみましょう。\n' +
                    '★ラッキーカラー　ゴールド\n' +
                    '★パワースポット　観葉植物のそば\n' +
                    '★ラッキーアイテム　手帳'
                },
                {
                    no: 2,
                    title: '風流人',
                    image: require('../../assets/cards/01.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は思いがけない周りの声や、噂話に傷つきそうになるかもしれませんが貴方はとても強い人です。嵐に負けない強い自分の心を信じて自分の心の声に耳を傾けてみましょう。自分が心地よいと感じることや自分が喜びを感じることの中に正解が隠れています。どんなに周りが騒がしくても、深呼吸をして自分の素晴らしさと繋がってみてくださいね。\n' +
                    '★ラッキーカラー　ゴールド\n' +
                    '★パワースポット　緑の大きな木\n' +
                    '★幸運の言霊　私は大丈夫'
                },
                {
                    no: 3,
                    title: '陰陽師',
                    image: require('../../assets/cards/02.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は新しい分野や未知の世界への探求を始める日。今まで興味をもっていたけれど学ばなかったことや、読まなかった本に思い切って挑戦してみましょう。新しい知識や学びが現状を打開し発展させる方向へと導いてくれます。また、人との会話や話題にも耳を傾けてみて。自分とは違う価値観の中に新たな気づきがあります。\n' +
                    '★ラッキーカラー　白\n' +
                    '★パワースポット　会議室や図書館\n' +
                    '★ラッキーフード　豆'
                },
                {
                    no: 4,
                    title: '陰陽師',
                    image: require('../../assets/cards/02.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日はイマイチやる気が出なかったり、気分が乗らない１日。そんな日は無理して頑張るのではなく、少し肩の力を抜いて休息を意識してみましょう。力を抜くことは悪い事ではありません。明日のためにも体力と精神力の温存に努めましょう。日頃無理をしすぎている人はこの機会に思い切って普段とは違う気分転換をするのも良い事です。\n' +
                    '★ラッキーカラー　白\n' +
                    '★パワースポット　喫茶店やカフェ\n' +
                    '★幸運の言霊　ありがとう'
                },
                {
                    no: 5,
                    title: '斎宮',
                    image: require('../../assets/cards/03.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は、ボランティア活動や小さな親切など「人のためになること」を意識して生活すると良い１日。貴方の優しさが他の人へ良い刺激となって幸せの輪を広げていきます。そんな貴方の姿を遠くから見つめて魅了される人や好意を抱く人も現れそうです。親切心と優しさが貴方の魅力を高めてくれることを忘れないでくださいね。\n' +
                    '★ラッキーカラー　青\n' +
                    '★パワースポット　噴水\n' +
                    '★ラッキーアイテム　ハンカチ'
                },
                {
                    no: 6,
                    title: '斎宮',
                    image: require('../../assets/cards/03.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は自然を意識して自分自身にエネルギーチャージをする１日。日常生活や毎日の仕事で枯渇したエネルギーを、自然の中を散歩したりゆっくり過ごしたりすることで補うことができます。もし孤独感を感じたなら、それは今まで自分に厳しくしすぎたせいかもしれません。肩の力を抜いて、自分に優しい言葉をかけてあげてくださいね。\n' +
                    '★ラッキーカラー　水色\n' +
                    '★パワースポット　花屋\n' +
                    '★幸運の言霊　私は私が大好き'
                },
                {
                    no: 7,
                    title: '皇后',
                    image: require('../../assets/cards/04.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は自分自身に向けられた愛情に気づく１日。家族や友人、恋人といった周囲の人にいかに自分が愛されているのか、それは決して目に見える形だけにとどまりません。今、こうしてこの場で過ごせていることが愛されている証の一つ。当たり前の日常という豊かさに目を向けてみると新しい幸せが見えてくるでしょう。\n' +
                    '★ラッキーカラー　オレンジ色\n' +
                    '★パワースポット　キッチン\n' +
                    '★ラッキーフード　旬の野菜'
                },
                {
                    no: 8,
                    title: '皇后',
                    image: require('../../assets/cards/04.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は我慢している自分自身を癒す１日。何気なくやり過ごしているけれど、実は毎日の生活の中で我慢していることや耐えていることがありそう。我慢や忍耐は「美徳」とも言われますが、それが全て良い事とは限りません。今日は自分の気持ちに正直に行動してみると吉。我慢していること、耐えていることを紙に書き出したり声に出してみることも効果的。\n' +
                    '★ラッキーカラー　コーラルピンク\n' +
                    '★パワースポット　カラオケ\n' +
                    '★ラッキーフード　スパークリングジュース\n'
                },
                {
                    no: 9,
                    title: '帝',
                    image: require('../../assets/cards/05.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は自信に満ちた気持ちで過ごせる１日。上手くいったことや嬉しいことがあったら「私って素晴らしい！」と自分を褒めてあげましょう。自分自身への自信と信頼が深まります。新しい仕事やプロジェクトなど新しいことを始めるのも吉。自分の価値を高めてくれる人間関係にも出会えそうです。\n' +
                    '★ラッキーカラー　赤\n' +
                    '★パワースポット　図書館\n' +
                    '★幸運の言霊　私って素晴らしい'
                },
                {
                    no: 10,
                    title: '帝',
                    image: require('../../assets/cards/05.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は謙虚さを意識してみると良い日。価値観が合わない人や自分の思い通りにならないことに対してイライラしてストレスを抱えてしまうのは良くありません。そんな時は「自分と相手は違う」という当たり前のことを改めて意識してみると良いでしょう。相手の環境や状況に思いを馳せることで良い意味で「仕方ない」と状況を許せる心持ちになれます。\n' +
                    '★ラッキーカラー　藍色\n' +
                    '★パワースポット　屋上\n' +
                    '★ラッキーフード　和食'
                },
                {
                    no: 11,
                    title: '大阿闍梨',
                    image: require('../../assets/cards/06.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は周りの人の優しさを強く感じられる１日になりそう。自己嫌悪になり自分を責めてしまいそうな時にも、周りの人の何気ない一言やアドバイスで救われることでしょう。人の優しさに触れ、人に優しくされることで自分も他人に優しくなれます。特に恋人からのアドバイスには冷静に耳を傾ける必要がありそうです。\n' +
                    '★ラッキーカラー　ピンク\n' +
                    '★パワースポット　神社\n' +
                    '★ラッキーアイテム　イヤリングorピアス'
                },
                {
                    no: 12,
                    title: '大阿闍梨',
                    image: require('../../assets/cards/06.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は信頼できる人や信頼できる物事に出会う日。新たな出会いだけではなく、今まで信頼をそれほど感じられないと思っていた人や物事への評価が良い方向へ変わることもありそう。今日はそんな自分の感覚を再確認して、安心して関係性を育んだり物事に取り組んでいける１日になりそうです。\n' +
                    '★ラッキーカラー　アイボリー\n' +
                    '★パワースポット　美容室\n' +
                    '★ラッキーフード　エスニック料理'
                },
                {
                    no: 13,
                    title: '戀人',
                    image: require('../../assets/cards/07.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は恋愛運に恵まれる１日。恋人やパートナーがいる人はその人との時間を大切に、いつも以上にコミュニケーションを深めること。相手の良さや素晴らしさを改めて再認識します。恋人募集中の人は、好きな人も含めて出会った人に積極的に話しかけるようにすると吉。挨拶や簡単な世間話でも些細な一言が貴方の印象を良いものにしてくれます。\n' +
                    '★ラッキーカラー　ピンク\n' +
                    '★パワースポット　洋服屋\n' +
                    '★ラッキーフード　フルーツ'
                },
                {
                    no: 14,
                    title: '戀人',
                    image: require('../../assets/cards/07.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は大好きな人のことを考えて想いが募る１日になりそう。その人と過ごす時間やデートのことを想像して幸せで楽しい気持ちになる反面、悪い想像を働かせて不安に駆られることも。全ては自分の中での想像に過ぎないので、それらの感情に囚われて仕事や勉強が疎かにならないよう注意が必要です。\n' +
                    '★ラッキーカラー　紫\n' +
                    '★パワースポット　温泉\n' +
                    '★幸運の言霊　私は愛されている'
                },
                {
                    no: 15,
                    title: '車',
                    image: require('../../assets/cards/08.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日はふと思い立った場所へ出掛けてみると良い１日。近場でも良いので、日常のルーティンから離れてみましょう。通勤ルートを変えてみたり、時間が許すなら一泊くらいの小旅行もお勧めです。日常に縛られない自由な時間と空間が貴方に良いインスピレーションと出逢いをもたらします。\n' +
                    '★ラッキーカラー　青\n' +
                    '★パワースポット　車の中\n' +
                    '★ラッキーアイテム　スマートフォン'
                },
                {
                    no: 16,
                    title: '車',
                    image: require('../../assets/cards/08.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は後輩や年下の人達の手助けをしてひらめきを得る１日。覚えるペースはゆっくりでも、貴方の能力や技術を必要としている人に分け与えることが将来的に貴方自身の大きな収穫となるでしょう。もし、自分には分からないことであっても一緒に取り組むことで自分自身も成長できるはずです。\n' +
                    '★ラッキーカラー　ペールピンク\n' +
                    '★パワースポット　映画館\n' +
                    '★ラッキーアイテム　エプロン'
                },
                {
                    no: 17,
                    title: '武士',
                    image: require('../../assets/cards/09.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日はとても充実した達成感を味わえる１日。仕事も恋愛も、自分なりに感じていた課題をクリア出来たり心配していたことが解決したりと、とても安心した幸せな気持ちを感じられるでしょう。目の前に現れる壁や障害は、貴方なら必ず乗り越えられるもの。今日は頑張った自分をしっかり褒めてあげてくださいね。「自分へのご褒美」も奮発すると吉。\n' +
                    '★ラッキーカラー　ベージュ\n' +
                    '★パワースポット　ケーキ屋\n' +
                    '★幸運の言霊　やればできる'
                },
                {
                    no: 18,
                    title: '武士',
                    image: require('../../assets/cards/09.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は思いがけない出来事に心が弱気になってしまうかも。何気ない他人の一言や態度にショックを受けたり落ち込んでしまいそうになったら、「自分は大丈夫」と口に出してみましょう。相手は深く考えてその言動をしている訳ではありませんので、気にする必要はありません。むしろ繊細な自分の心を認め、感受性豊かな自分に誇りを持ってください。\n' +
                    '★ラッキーカラー　レモンイエロー\n' +
                    '★パワースポット　コンサートホール\n' +
                    '★幸運の言霊　大丈夫'
                },
                {
                    no: 19,
                    title: '隠者',
                    image: require('../../assets/cards/10.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は目に見えない世界を意識して感じてみると良い１日。ふと周りを見渡してみると、空気も、電波も、香りも、人の気持ちも、目で見ることは出来ませんが確実に存在しています。そんな視覚から離れた世界を感じてみることで、今まで以上に豊かさに恵まれる日になることでしょう。静かな場所で瞑想してみるのもおすすめです。\n' +
                    '★ラッキーカラー　黄緑色\n' +
                    '★パワースポット　プラネタリウム\n' +
                    '★ラッキーアイテム　アロマオイル'
                },
                {
                    no: 20,
                    title: '隠者',
                    image: require('../../assets/cards/10.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は古いものや伝統のあるものについて理解を深める１日。自分自身の過去を振り返って、子ども時代の思い出やその頃の記憶を辿ってみるのも良いです。「今ここ」にあるものには全てにそれぞれの歴史や経過が存在しています。それらに思いを寄せることが今の貴方の生活の質を高めてくれることでしょう。仕事のアイディアやヒントも見つけられそうです。\n' +
                    '★ラッキーカラー　シルバー\n' +
                    '★パワースポット　お寺\n' +
                    '★ラッキーフード　お米'
                },
                {
                    no: 21,
                    title: '春夏秋冬',
                    image: require('../../assets/cards/11.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は運命の出会いに恵まれる１日。たとえその時、その人との縁を「運命的」とは思えなくても、後から思い返すと今日この日がターニングポイントとなっていることに気付くはず！肩の力を抜いて、自然体の貴方で過ごすことが今日のポイントです。人との出会いだけではなく仕事や環境などこれから自分にとって重要な意味を持つものにも繫がりそうです。\n' +
                    '★ラッキーカラー　茶色\n' +
                    '★パワースポット　日当たりの良い屋外\n' +
                    '★ラッキーアイテム　天然石'
                },
                {
                    no: 22,
                    title: '春夏秋冬',
                    image: require('../../assets/cards/11.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は予定通りに物事が進まず、落ち着かない１日になりそう。ストレスや緊張感を感じたら、無理をせず休息をとったり体を動かしてみたりと気分転換に努めることです。目を閉じて深い深呼吸をしてみるのもオススメです。外の新鮮な空気を吸って身体と心のリフレッシュに気を配ってみてください。\n' +
                    '★ラッキーカラー　緑色\n' +
                    '★パワースポット　公園\n' +
                    '★幸運の言霊　がんばらない'
                },
                {
                    no: 23,
                    title: '勧善懲悪',
                    image: require('../../assets/cards/12.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は、「正直であること・素直であること」が物事の明暗を分ける日。その場しのぎの嘘はもちろん、たとえ相手を思いやっての嘘であっても最終的には良くない結果を引き寄せて自分自身を苦しめることになります。一時はバツの悪さを感じたとしても正直であること、誠実であることを心掛けて１日を過ごしてみてください。\n' +
                    '★ラッキーカラー　青紫\n' +
                    '★パワースポット　大きな時計\n' +
                    '★ラッキーフード　新鮮な野菜'
                },
                {
                    no: 24,
                    title: '勧善懲悪',
                    image: require('../../assets/cards/12.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は自分の意見を明確に主張することが物事を動かす日。普段は相手や周囲の様子を伺って言えなかったことも、今日は勇気を出してきちんと言葉にして伝えてみましょう。言葉にしなくては伝わらないこともあります。貴方の本音は愛のあるメッセージとして相手の心へ届き、周囲を変えることができるでしょう。\n' +
                    '★ラッキーカラー　ローズレッド\n' +
                    '★パワースポット　和風建築\n' +
                    '★ラッキーアイテム　ハンカチ'
                },
                {
                    no: 25,
                    title: '流刑者',
                    image: require('../../assets/cards/13.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は自分にとって大きな壁を乗り越えることになる１日。苦しく辛く感じることがあっても、必ず乗り越えられるので今は逃げないことが肝心です。緊張感で苦しさを感じたら、深呼吸をして温かいお茶でリラックスすること。心配しなくても大丈夫、貴方なら必ず乗り越えられます。\n' +
                    '★ラッキーカラー　白\n' +
                    '★パワースポット　海\n' +
                    '★ラッキーアイテム　ティーカップ'
                },
                {
                    no: 26,
                    title: '流刑者',
                    image: require('../../assets/cards/13.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は、自分のことを後回しにして他人のために働くことが多い一日。他人への思いやりを持つことは素晴らしいことですが、自分を犠牲にして相手に尽くすのは心苦しさを感じるはず。今一度「自分にとっての心地よさ」を自分自身に問いかけてみる必要がありそうです。必要以上に相手のわがままや要望に付き合う必要はありません。\n' +
                    '★ラッキーカラー　朱赤\n' +
                    '★パワースポット　エレベーター\n' +
                    '★ラッキーフード　香辛料・スパイス'
                },
                {
                    no: 27,
                    title: '閻魔',
                    image: require('../../assets/cards/14.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は物事に区切りをつけるのに最適な日。もう着なくなった服や必要の無くなった物事を断捨離し、大掃除をするのもオススメです。古いものを手放すと新しい流れが起こり新たな縁やエネルギーが入ってきます。今一度自分の身の回りを見渡して、今の自分には必要のない事を整理してみてください。\n' +
                    '★ラッキーカラー　青\n' +
                    '★パワースポット　ホームセンター\n' +
                    '★幸運の言霊　感謝して手放します'
                },
                {
                    no: 28,
                    title: '閻魔',
                    image: require('../../assets/cards/14.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は貴方自身の魅力が発揮されて光り輝く１日。今まで苦手だと感じていたことも意外に早く片付いたり、初めての作業がスムーズに運んだりと物事も順調に運びそう。今日は運気も高いので勝負事や告白にも向いています。思い切っていつもより大胆に行動してみて。\n' +
                    '★ラッキーカラー　黄色\n' +
                    '★パワースポット　競馬場\n' +
                    '★ラッキーアイテム　ゴールドの指輪'
                },
                {
                    no: 29,
                    title: '宴',
                    image: require('../../assets/cards/15.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は、自分自身を労る１日。毎日の忙しさで疎かにしてきた体のケアや食事、生活習慣を見直して自分自身にたっぷり栄養と休養をあげましょう。髪や肌、体の調子が整うと気持ちも変わり、運気も大きく向上します。日頃運動不足の人は外でのウォーキングや軽い運動もオススメ。健康に対する意識を高めてみましょう。\n' +
                    '★ラッキーカラー　オレンジ色\n' +
                    '★パワースポット　水辺のカフェ\n' +
                    '★ラッキーアイテム　体を締め付けない服'
                },
                {
                    no: 30,
                    title: '宴',
                    image: require('../../assets/cards/15.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は音楽に縁のある１日。鼻歌やカラオケでも良いので、自分の好きな歌を口ずさんでみましょう。思いがけず歌詞の中にハッとする閃きや今の貴方の助けとなるメッセージが隠れています。気分が乗ったら自分で作詞や作曲をしてみるのもオススメです！自分の中に隠れている新たな才能や喜びに気付くきっかけになるかもしれません。\n' +
                    '★ラッキーカラー　水色\n' +
                    '★パワースポット　ライブハウス\n' +
                    '★ラッキーアイテム　腕時計'
                },
                {
                    no: 31,
                    title: '般若',
                    image: require('../../assets/cards/16.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は嫉妬心に囚われたり、自信のなさを感じてネガティブな気持ちに振り回されやすい１日。起きてしまった物事にばかりフォーカスせず、自分自身の気持ちを見つめて感情を解放してみましょう。泣いたり叫んだり、時には感情を表現することも大切です。感情が解放されると、実は思い込みや誤解にすぎないと気づく事ができるでしょう。\n' +
                    '★ラッキーカラー　ローズピンク\n' +
                    '★パワースポット　山\n' +
                    '★ラッキーフード　果物\n'
                },
                {
                    no: 32,
                    title: '般若',
                    image: require('../../assets/cards/16.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は周りの人を助けることで自分が輝ける１日。普段は無関心で通り過ぎてしまうような街角や駅の雑踏を、今日だけは少し意識を変えて見つめてみてください。助けを必要としている人の姿を見かけたら、出来る範囲で良いので小さな手助けを。今日の親切が後々大きなエネルギーとして戻ってくる事でしょう。\n' +
                    '★ラッキーカラー　白\n' +
                    '★パワースポット　交差点\n' +
                    '★幸運の言霊　情けは人のためならず'
                },
                {
                    no: 33,
                    title: '雷',
                    image: require('../../assets/cards/17.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は、思いがけない出来事やニュースに衝撃を受ける日。その出来事によって今までの自分の価値観や常識が覆されるかもしれませんが、この経験が今後の貴方の活躍のきっかけになりそう。今はショックが大きくても、次第に貴方の視野は広がり物事を以前よりもずっと柔軟に考えられるようになります。\n' +
                    '★ラッキーカラー　黒\n' +
                    '★パワースポット　農園\n' +
                    '★ラッキーフード　ポタージュスープ'
                },
                {
                    no: 34,
                    title: '雷',
                    image: require('../../assets/cards/17.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は、自分にはどうにも出来ないことで不安な気持ちや緊張感を感じる１日になりそう。自分で何とかしようとするよりも、今回は周囲の様子を伺いながら流れに乗るというのが最適な解決方法。ただし、自分自身が周囲に流されるのではなく、自分の意思で流れを乗りこなすという意志は必要です。被害者意識に囚われず進む事が肝心です。\n' +
                    '★ラッキーカラー　紫\n' +
                    '★パワースポット　吹き抜けの高い天井\n' +
                    '★幸運の言霊　良い流れを引き寄せる'
                },
                {
                    no: 35,
                    title: '望月',
                    image: require('../../assets/cards/18.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は何事も楽しく取り組める１日。仕事を任されたなら、義務感ではなく工夫したり効率を考えながら自分なりの楽しめるやり方を見出す喜びを味わうことが出来るでしょう。デートするなら貴方の方から積極的に行き先やプランの提案をしてみて。とても楽しく充実した１日が過ごせるでしょう。\n' +
                    '★ラッキーカラー　サーモンピンク\n' +
                    '★パワースポット　噴水\n' +
                    '★ラッキーアイテム　爪切り'
                },
                {
                    no: 36,
                    title: '望月',
                    image: require('../../assets/cards/18.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は思いもよらない恋に目覚めたり、一目惚れに陥る可能性が高い１日。今まで近くにいた人がふと素敵に思えて恋に落ちたり、今日出会った人に一瞬で心を奪われるような、そんな素敵な経験をしたなら、その瞬間に相手に微笑みかけてみて。今日の恋がこれからの貴方を今以上に魅力的に成長させてくれるでしょう。\n' +
                    '★ラッキーカラー　水色\n' +
                    '★パワースポット　カフェラウンジ\n' +
                    '★ラッキーフード　プチケーキ'
                },
                {
                    no: 37,
                    title: '闇夜',
                    image: require('../../assets/cards/19.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日はふとしたことから人を信じられなくなったり、周囲へ不信感を募らせてしまいそうな１日。「もう誰も信じられない」と悲しい気持ちにもなりそうですが、実はその不信感の根底には不確かな噂話や未確認の情報がありそうです。噂話や又聞きの情報ではなく、常に自分の目で見たことや事実を確認するように心掛けましょう。\n' +
                    '★ラッキーカラー　深緑色\n' +
                    '★パワースポット　ログハウス\n' +
                    '★幸運の言霊　良いことが起こる'
                },
                {
                    no: 38,
                    title: '闇夜',
                    image: require('../../assets/cards/19.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日はとても嬉しい出来事に出会える日。たとえ他の人には取るに足らない出来事であったとしても、貴方にしか分からない貴方だけの喜びや楽しみを見つけて幸せな気分を感じられるでしょう。この喜びは、貴方が独り占めしても大丈夫！自分の中から湧き上がる楽しい気持ちを大切にしてください。\n' +
                    '★ラッキーカラー　ペールピンク\n' +
                    '★パワースポット　遊園地\n' +
                    '★ラッキーアイテム　キャラクターのキーホルダー'
                },
                {
                    no: 39,
                    title: '祭',
                    image: require('../../assets/cards/20.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は、友人や仲間と大人数で楽しむことに縁のある日。野外でのパーティーやハイキングなど大勢で自然を感じながらの活動も良い刺激を与えてくれそうな予感。また同じ場所に集まれなくても、オンラインやチャットなどで人の繋がりを感じることでも大きな幸せと喜びを感じられることでしょう。\n' +
                    '★ラッキーカラー　オレンジ\n' +
                    '★パワースポット　野外\n' +
                    '★ラッキーフード　肉料理'
                },
                {
                    no: 40,
                    title: '祭',
                    image: require('../../assets/cards/20.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は家族との関わりを大切にする日。仕事や趣味など忙しく予定が入っていたとしても、なるべく多くの時間を家族と過ごすように心掛けてみましょう。家族と離れて暮らしているなら、電話やメールで連絡を取り合うのも良いですね。家族との関わりの中で、自分にとって大切な気付きが得られます。\n' +
                    '★ラッキーカラー　オレンジ\n' +
                    '★パワースポット　キッチン\n' +
                    '★ラッキーアイテム　折り畳み傘'
                },
                {
                    no: 41,
                    title: '出家',
                    image: require('../../assets/cards/21.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は懐かしい人に再会したり昔の思い出がよみがえって過去を振り返ることになる１日。今まで忘れていた記憶を思い出したり、久しく連絡をとっていない人から連絡が来たりするでしょう。過去を今・未来へ生かすターニングポイントとなる１日なので、今日の出来事を明日以降の選択に生かすと良さそうです。\n' +
                    '★ラッキーカラー　ピンク\n' +
                    '★パワースポット　居酒屋\n' +
                    '★ラッキーアイテム　名刺入れ'
                },
                {
                    no: 42,
                    title: '出家',
                    image: require('../../assets/cards/21.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は思い切って自分の装いを変えてみる日。メイクでもファッションでも、今までの自分のスタイルを変えて新しい色やデザインにチャレンジしてみましょう。新しい服を買いに行ったり美容院で髪を切るのも良い日です。新しいスタイルは最初は違和感を感じるかもしれませんが、意外にも周囲からは高評価で貴方のファンが増えそうです。\n' +
                    '★ラッキーカラー　薄紫色\n' +
                    '★パワースポット　美容院\n' +
                    '★ラッキーアイテム　革靴'
                },
                {
                    no: 43,
                    title: '極楽浄土',
                    image: require('../../assets/cards/22.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今日は自分なりに何かをやり遂げた達成感に満たされる１日。仕事で思いがけない成果を上げたり、気になっている人と急接近できたりと自分の望んでいた結果がそれ以上の形で返ってくる可能性も高いです。今日の結果を自分の自信に変えて、次のステップへ挑戦してみてください。貴方の真の成功はこれからやってきます！\n' +
                    '★ラッキーカラー　パールホワイト\n' +
                    '★パワースポット　高いところ\n' +
                    '★幸運の言霊　私には無限の力が備わっている'
                },
                {
                    no: 44,
                    title: '極楽浄土',
                    image: require('../../assets/cards/22.jpg'),
                    position: Constants.CardPosition.Reverse,
                    message: '今日は目標達成のための基盤を作る日。自分なりに叶えたい夢や達成したい目標があるなら、そのための具体的な方法や道筋を考えて一度書き出してみましょう。イメージが明確になり目標達成への近道が見えてきます。身近にその目標を達成している人がいるならその人の話を聞いてみたり、夢を叶えた人の本を読んだり映画を見るのも吉。\n' +
                    '★ラッキーカラー　黄色\n' +
                    '★パワースポット　学校\n' +
                    '★ラッキーアイテム　万年筆'
                },

            ]
            this.saveLocalStorage();
        }
    }
};

// CardsAPI.init();
export default CardsAPI;
