/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import {ImageBackground, StyleSheet, View, Image, Text, ScrollView, Dimensions} from 'react-native';
import { NativeBaseProvider } from "native-base";

import Layout from "./../constants/Layout";
import Styles from "./../constants/Styles";

import ActionButton from "./../components/ActionButton";
import ScreenHeader from "../components/ScreenHeader";

import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

const {width} = Dimensions.get('window');

export default class BirthdayFortuneTellingResult extends React.PureComponent {

    render() {

        return (
            <NativeBaseProvider>
                <StatusBar hidden/>
                <ImageBackground source={require('../../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={'生年月日で占う'} navigation={this.props.navigation} left={false}/>
                    {this.renderResult()}
                    <View style={styles.bottom}>
                        <View style={[Styles.common.Center, {flex: 1, flexDirection: 'row', bottom: Layout.screen.height*.02}]}>
                            <ActionButton light navigation={this.props.navigation} title={'トップへ戻る'} action={'Top'}/>
                        </View>
                        <ActionButton navigation={this.props.navigation} title={'監修者に個人鑑定を依頼する'} url={Constants.ApplicationUrl}/>
                    </View>
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    renderResult(){

        var card = null;
        if(!Entities.isEmpty(this.props.navigation.state.params)){
            card = this.props.navigation.state.params.data;
        }

        return (
            <View style={styles.middle}>

                <Text style={styles.name}>{this.getPosition(card)}</Text>
                <Image source={require('../../assets/images/card-result.png')}
                       style={styles.result}/>
                {this.renderCard(card)}
                <Text style={styles.title}>{card.title}</Text>
                <Image source={require('../../assets/images/panel-message.png')}
                       style={styles.message}/>
                <View style={styles.scroll}>
                    <ScrollView vertical>
                        <Text style={styles.text}>{card.message}</Text>
                    </ScrollView>
                </View>
            </View>
        );
    }

    renderCard(card){

        if(!Entities.isEmpty(card.position)){

            switch (card.position){
                case Constants.CardPosition.Upright:
                    return(
                        <Image source={card.image} style={styles.card}/>
                    );
                case Constants.CardPosition.Reverse:
                    return(
                        <Image source={card.image} style={[styles.card, {transform: [{ rotate: '180deg' }]}]}/>
                    );
            }
        }
        return '';
    }

    getPosition(card){
        if(!Entities.isEmpty(card.position)){

            switch (card.position){
                case Constants.CardPosition.Upright:
                    return '正位置';
                case Constants.CardPosition.Reverse:
                    return '逆位置';
            }
        }
        return '';
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        alignItems: 'center',
        position: 'absolute',
        bottom: Layout.screen.height*.1,

        width:  Layout.screen.width,
        height: Layout.screen.height*.8,
    },
    name: {
        fontFamily: 'KouzanGyousho',
        fontSize: Layout.screen.height*.1*.26,
        color: '#70615A',

        alignItems: 'center',
        justifyContent: 'center',

        top: 0,
        height: Layout.screen.height*.04
    },
    result: {
        position: 'absolute',
        top: Layout.screen.height*.025,

        width: Layout.screen.width*.578,
        height: Layout.screen.width*.578*1.78
    },
    card: {
        position: 'absolute',
        top: Layout.screen.height*.048,

        width: Layout.screen.width*.47,
        height: Layout.screen.width*.47*1.71
    },
    title: {
        fontFamily: 'YuMincho',
        fontSize: Layout.screen.height*.1*.22,

        color: '#70615A',

        alignItems: 'center',
        justifyContent: 'center',

        position: 'absolute',
        top: Layout.screen.width*.578*1.78*.9
    },
    message: {
        position: 'absolute',
        top: Layout.screen.height*.588,

        width: Layout.screen.width*.846,
        height: Layout.screen.width*.846*.36,
    },
    scroll: {
        position: 'absolute',
        top: Layout.screen.height*.61,

        width: Layout.screen.width*.8,
        height: Layout.screen.width*.8*.27,
    },
    text: {
        fontFamily: 'YuMincho',
        fontSize: width*.4*.33*.30,

        color: '#70615A',
        paddingLeft: Layout.screen.width*.02,
        width: Layout.screen.width*.76,

        lineHeight: width*.4*.33*.32,
    },
    controls: {
        width: Layout.screen.width*.44,
        height: Layout.screen.width*.44*.33
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.1,

        position: 'absolute',
        bottom: 5
    }
});
