/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
export default {

    CARDS_TODAY_SHUFFLE: 7,
    CARDS_TOTAL: 44,

    CARDS_TODAY_ADVICE: 3,
    CARDS_TODAY_ADVICE_TOTAL: 22,

    CARDS_BIRTHDAY_TOTAL: 22,

    /**
     *
     */
    SHOW_RESULT_TIMEOUT: 8000,

    /**
     *
     */
    PLAYBACK_TIMEOUT: 30,

    ViewPort: {
        Tiny: { width: 320, height: 568 },
        Small : { width: 375, height: 667 },
        Medium : { width: 414, height: 736 },
        High : { width: 375, height: 812 },
        Full : { width: 414, height: 896 }
    },

    Loop: {
        Day : 'day',
        Evening : 'evening',
        Night : 'night',
    },

    CardPosition: {
        Upright: 1,
        Reverse: 2
    },

    AdviceType: {
        Love: 0,
        Business: 1,
        Relationship: 2
    },

    Orientation: {
        Portrait : 'portrait',
        Landscape : 'landscape'
    },

    ADsEnabled: false,

    ApplicationUrl: 'https://heian-emaki-tarot.jimdofree.com'
}
