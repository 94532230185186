/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, Dimensions, StyleSheet, View, TouchableOpacity, Image, Text } from 'react-native';
import { NavigationActions, NavigationEvents } from "react-navigation";
import { NativeBaseProvider } from "native-base";

import Layout from "./../constants/Layout";
import Styles from "../constants/Styles";

import ScreenHeader from "../components/ScreenHeader";
import ScreenHint from "../components/ScreenHint";
import Constants from "../constants/Constants";
import Entities from "../utils/Entities";

import LoveAdviceCardsAPI from "../api/LoveAdviceCardsAPI";
import BusinessAdviceCardsAPI from "../api/BusinessAdviceCardsAPI";
import RelationshipAdviceCardsAPI from "../api/RelationshipAdviceCardsAPI";

const {width, height} = Dimensions.get('window');

export default class AdviceMessageStart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            advices: ['恋愛', '仕事', '人間関係'],
            cards: this.createCards()
        };
    }

    render() {

        let items = this.state.cards.map((item, i) => {
            return this.renderAdvise(item, i);
        });

        return (
            <NativeBaseProvider>
                <NavigationEvents onWillFocus={(payload) => {

                    if(!Entities.isEmpty(payload.state.params)){
                        if(!payload.state.params.reset)
                            return;
                    }
                    this.setState({cards: this.createCards()});
                }}/>
                <NavigationEvents onDidBlur={(payload) => {
                    // this.setState({cards: []});
                }}/>
                <StatusBar hidden/>
                <ImageBackground source={require('../../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>
                    <ScreenHeader title={'アドバイスメッセージ'} navigation={this.props.navigation}/>
                    <View style={styles.middle}>
                        <ScreenHint text={'貴方に幸運を呼ぶアドバイス'}/>
                        {items}
                    </View>
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    createCards(){
        const cards = [
            this.getLoveAdviceCard(),
            this.getBusinessAdviceCard(),
            this.getRelationshipAdviceCard()
        ];
        return cards;
    }

    /**
     *
     */
    getLoveAdviceCard(){
        const result = LoveAdviceCardsAPI.get(Math.floor(Math.random() * Constants.CARDS_TODAY_ADVICE_TOTAL) + 1);
        if(!result.success){
            return {};
        }
        return result.data;
    }

    /**
     *
     */
    getBusinessAdviceCard(){
        const result = BusinessAdviceCardsAPI.get(Math.floor(Math.random() * Constants.CARDS_TODAY_ADVICE_TOTAL) + 1);
        if(!result.success){
            return {};
        }
        return result.data;
    }

    /**
     *
     */
    getRelationshipAdviceCard(){
        const result = RelationshipAdviceCardsAPI.get(Math.floor(Math.random() * Constants.CARDS_TODAY_ADVICE_TOTAL) + 1);
        if(!result.success){
            return {};
        }
        return result.data;
    }

    renderAdvise(card, i){
        return (
            <View key={card.title+i} style={styles.advice}>
                <Image style={styles.panel} source={require('../../assets/images/panel-advice.png')}/>
                <Image style={[styles.card, ]} source={card.image}/>
                <Text style={styles.name}>{card.title}</Text>

                <Text style={styles.title}>{this.state.advices[i]}</Text>
                <TouchableOpacity style={[styles.controls, Styles.common.Center]} activeOpacity={.8} onPress={() => {
                    this.navigateToScreen('AdviceResult', this.state.advices[i] + 'アドバイスメッセージ', card);
                }}>
                </TouchableOpacity>
            </View>
        );
    }

    /**
     *
     */
    navigateToScreen(name, title, card){
        const navigationAction = NavigationActions.navigate({
            routeName: name,
            params: {
                title: title,
                data: card
            }
        });

        if(!Entities.isEmpty(this.props.navigation)){
            this.props.navigation.dispatch(navigationAction);
        }
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        width:  Layout.screen.width,
        height: Layout.screen.height*.8,

        position: 'absolute',
        bottom: Layout.screen.height/16
    },
    advice: {
        marginBottom: 10,
        width:  Layout.screen.width*.837,
        height: Layout.screen.width*.837*.462
    },
    panel: {
        width:  Layout.screen.width*.837,
        height: Layout.screen.width*.837*.462,
    },
    card: {
        position: 'absolute',

        width: Layout.flipCard.width,
        height: Layout.flipCard.height,

        top: Layout.flipCard.height*.05,
        left: Layout.screen.width*.13 - Layout.flipCard.width*.5,
    },
    name: {
        position: 'absolute',

        top: Layout.flipCard.height*1.22,
        width: Layout.screen.width*.26,

        textAlign:'center',
        color: '#fff',
    },
    title: {
        position: 'absolute',

        top: Layout.flipCard.height*.3,
        right: width*.085,

        width: Layout.screen.width*.4,

        fontFamily: 'YuMincho',
        fontSize: 16,
        textAlign:'center',

        color: '#70615A'
    },
    controls: {
        position: 'absolute',

        top: Layout.flipCard.height*.78,
        right: width*.085,

        width: Layout.screen.width*.4,
        height: Layout.screen.width*.4*.28
    }
});