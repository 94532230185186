
import React from 'react';
import moment from "moment/moment";

import Constants from "../constants/Constants";

class Entities {}

Entities.isFunction = function (o) {
    return typeof o === "function";
};
Entities.isString = function (o) {
    return typeof o === "string";
};
Entities.isBoolean = function (o) {
    return typeof o === 'boolean' || o instanceof Boolean;
};
Entities.isNumber = function (o) {
    return typeof o === 'number' || o instanceof Number;
};
Entities.isObject = function (o) {
    return typeof o === "object";
};
Entities.isEmpty = function (o) {
    return !Entities.isDefined(o) || o === null || o === "" || (Array.isArray(o) && o.length == 0);
};
Entities.isJsonEmpty = function (o) {
    return Entities.isEmpty(o) || o === "\"\"";
};
Entities.isDefined = function (o) {
    return o !== undefined;
};
Entities.isPrimitive = function (o) {
    return Entities.isBoolean(o) || Entities.isString(o) || Entities.isNumber(o);
};
Entities.isValidRange = function (minValue, maxValue) {
    if(Entities.isEmpty(minValue) || Entities.isEmpty(maxValue))
        return true;

    var min = parseInt(minValue, 10);
    var max = parseInt(maxValue, 10);

    return min < max;
};
Entities.isIterable = function (o) {
    return Entities.isObject(o) || Object.isArray(o);
};
/**
 * Returns a random number between min (inclusive) and max (exclusive)
 */
Entities.getRandomMinMax = function (min, max) {
    return Math.random() * (max - min) + min;
};
Entities.isDigits = function (str) {
    var expression = /^[0-9\b]+$/;
    var regex = new RegExp(expression);

    return str.match(regex) || str == '';
};
Entities.isPhone = function (str) {
    var expression = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    var regex = new RegExp(expression);

    return str.match(regex)
};
Entities.isURL = function (str) {
    var expression = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);

    return str.match(regex)
};
Entities.isValidPassword = function (password) {
    return /^(?=.*[\d])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*])[\w!@#$%^&*]{8,15}$/.test(password);
};
Entities.isValidEmail = function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};
Entities.clone = function (object) {
    return JSON.parse(JSON.stringify(object));
};

Entities.isToday = function (date) {
    if(date == null) return false;

    return moment().format('DD.MM.YYYY') === moment(date, 'DD.MM.YYYY');
};

Entities.padNumber = function (value) {
    if(!Entities.isNumber(value))
        return value;

    return value.toString().padStart(2, '0');
};

Entities.padLotteryNumber = function (value) {
    return value;
    // if(!Entities.isNumber(value))
    //     return value;
    //
    // return value.toString().padStart(4, '0');
};

Entities.removeDuplicates = function(array){
    return array.filter((a, b) => array.indexOf(a) === b);
};

/**
 * Returns an array with arrays of the given size.
 *
 * @param array {Array} Array to split
 * @param size {Integer} Size of every group
 */
Entities.chunks = function(array, size){
    var results = [];

    while (array.length) {
        results.push(array.splice(0, size));
    }
    return results;
}

/**
 *
 * @param arrayA First array
 * @param arrayB Second array
 * @returns {number} Number of matches
 */
Entities.countMatches = function (arrayA, arrayB) {
    var matches = 0;
    for (var i=0; i<arrayA.length; i++) {
        if (arrayB.indexOf(arrayA[i]) != -1)
            matches++;
    }
    return matches;
}

Entities.isEquals = function (arrayA, arrayB) {
    if(arrayA.length != arrayB.length)
        return false;

    arrayA = arrayA.slice().sort((a, b) => a - b);
    arrayB = arrayB.slice().sort((a, b) => a - b);

    return arrayA.join('') === arrayB.join('');
}

Entities.isSame = function (arrayA, arrayB) {
    if(arrayA.length != arrayB.length)
        return false;

    return arrayA.join('') === arrayB.join('');
}

Entities.sortNumbers = function(numbers){
    numbers.sort((a, b) => {
        if(Entities.isEmpty(a))
            return true;

        if(Entities.isEmpty(b))
            return false;

        return a - b;
    });
}

Entities.concatNumbers = function(a, b){
    return a.concat(b.filter((item) => a.indexOf(item) < 0))
}

/**
 *
 * @param number Number for conversion to array of digits
 * @returns {number[]}
 */
Entities.numberToDigits = function (number, length=4){
    return Array.from(number.toString().padStart(length, '0')).map(Number);
}

/**
 *
 * @param width
 * @param height
 * @returns {*} ViewPort dimension
 */
Entities.getViewPort = function (width, height){

    if(height <= Constants.ViewPort.Tiny.height){
        return Constants.ViewPort.Tiny;
    }

    if(height <= Constants.ViewPort.Small.height){
        return Constants.ViewPort.Small;
    }

    if(height <= Constants.ViewPort.Medium.height){
        return Constants.ViewPort.Medium;
    }

    if(height <= Constants.ViewPort.High.height){
        return Constants.ViewPort.High;
    }
    return Constants.ViewPort.Full;
}

export default Entities;