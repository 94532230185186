/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";

import {ScrollView, StyleSheet, Dimensions, Image, Text, TouchableOpacity} from 'react-native';
import {createAppContainer, StackActions, SafeAreaView} from "react-navigation";

import { createStackNavigator } from 'react-navigation-stack';
import { createDrawerNavigator } from 'react-navigation-drawer';

import TopScreen from "./TopScreen";
import AboutScreen from "./AboutScreen";
import SupervisorScreen from "./SupervisorScreen";

import Layout from "./constants/Layout";
import Colors from "./constants/Colors";

import TodayFortuneTellingStart from "./screens/TodayFortuneTellingStart";
import TodayFortuneTellingShuffle from "./screens/TodayFortuneTellingShuffle";
import TodayFortuneTellingProcess from "./screens/TodayFortuneTellingProcess";
import TodayFortuneTellingResult from "./screens/TodayFortuneTellingResult";

import BirthdayFortuneTellingStart from "./screens/BirthdayFortuneTellingStart";
import BirthdayFortuneTellingResult from "./screens/BirthdayFortuneTellingResult";

import AdviceMessageStart from "./screens/AdviceMessageStart";
import AdviceMessageResult from "./screens/AdviceMessageResult";
import Entities from "./utils/Entities";
import Constants from "./constants/Constants";

const {width, height} = Dimensions.get('screen');

const TodayFortuneTellingStack = createStackNavigator({
        // Today’s message first page
        TodayStart: {
            screen: TodayFortuneTellingStart,
            navigationOptions: { headerShown: false }
        },
        // Shuffle page
        TodayShuffle: {
            screen: TodayFortuneTellingShuffle,
            navigationOptions: { headerShown: false }
        },
        // Shuffle completion page
        TodayProcess: {
            screen: TodayFortuneTellingProcess,
            navigationOptions: { headerShown: false }
        },
        TodayResult: {
            screen: TodayFortuneTellingResult,
            navigationOptions: { headerShown: false }
        }
    },
    {
        initialRouteName: 'TodayStart',
        defaultNavigationOptions: {
            headerShown: false,
            gestureEnabled: false
        }
    }
);

const BirthdayFortuneTellingStack = createStackNavigator({
        BirthdayStart: {
            screen: BirthdayFortuneTellingStart,
            navigationOptions: { headerShown: false }
        },
        BirthdayResult: {
            screen: BirthdayFortuneTellingResult,
            navigationOptions: { headerShown: false }
        }
    },
    {
        initialRouteName: 'BirthdayStart',
        defaultNavigationOptions: {
            headerShown: false,
            gestureEnabled: false
        }
    }
);

const AdviceStack = createStackNavigator({
        AdviceStart: {
            screen: AdviceMessageStart,

            navigationOptions: { headerShown: false }
        },
        AdviceResult: {
            screen: AdviceMessageResult,
            navigationOptions: { headerShown: false }
        }
    },
    {
        initialRouteName: 'AdviceStart',
        defaultNavigationOptions: {
            headerShown: false,
            gestureEnabled: false
        }
    }
);

const viewport = Entities.getViewPort(Layout.screen.width, Layout.screen.height);


export class SidebarDrawerContentComponent extends React.Component {
    constructor(props) {
        super(props);

        let routes = [
            {route: "Top", text: 'TOP'},
            {route: "TodayStart", text: '今日のメッセージ'},
            {route: "BirthdayStart", text:'生年月日で占う'},
            {route: "AdviceStart", text:'アドバイスメッセージ'},
            {route: "Supervisor", text:'監修者について'},
            {route: "About", text:'平安絵巻タロットについて'},
        ];

        this.state = {routes: routes };
    }

    render() {
        let items = this.state.routes.map((item, i) => {
            return (
                <TouchableOpacity key = {'sl-'+i} style={{ paddingTop: 10,  paddingBottom: 10 }}
                      onPress={() => {
                          this.props.navigation.closeDrawer();

                          this.props.navigation.dispatch(StackActions.popToTop({immediate: true}));
                          this.props.navigation.navigate(item.route);
                      }}>
                    <Text style={styles.text}>{item.text}</Text>
                </TouchableOpacity>
            )
        });

        return (
            <ScrollView>
                <SafeAreaView
                    style={{ flex: 1 }}
                    forceInset={{ top: 'always', horizontal: 'never' }}>
                    <Image
                        square
                        style={styles.logo}
                        source={require('../assets/images/logo.png')}
                    />
                    {items}
                    {/*<DrawerItems {...props} />*/}
                </SafeAreaView>
            </ScrollView>
        );
    }
}

const MainScreenRouter = createDrawerNavigator(
    {
        Top: {screen: TopScreen, navigationOptions: () => ({ title: 'TOP'})},
        Today: { screen: TodayFortuneTellingStack,  navigationOptions: () => ({ title: '今日のメッセージ'})},
        Birthday: {screen: BirthdayFortuneTellingStack, navigationOptions: () => ({ title: '生年月日で占う'})},
        Advice: {screen: AdviceStack, navigationOptions: () => ({ title: 'アドバイスメッセージ'})},
        Supervisor: {screen: SupervisorScreen, navigationOptions: () => ({ title: '監修者について'})},
        About: {screen: AboutScreen, navigationOptions: () => ({ title: '平安絵巻タロットについて'})},
    },
    {
        contentOptions: {
            activeTintColor: '#70615a',
            activeBackgroundColor: 'transparent',
            inactiveTintColor: '#70615a',
            inactiveBackgroundColor: 'transparent',
            labelStyle: {
                fontSize: 14,
                margin: 10,

                fontFamily: 'YuMincho'
            },
            itemsContainerStyle: {
                marginTop: width*.3*.66 + ((viewport == Constants.ViewPort.High || viewport == Constants.ViewPort.Full)?128:16),
            }
        },

        contentComponent: props => <SidebarDrawerContentComponent {...props} />,
        initialRouteName: 'Top',
        drawerPosition: "right",
        drawerWidth: Layout.screen.width * .6,
        drawerBackgroundColor: Colors.themeTransparent,
        unmountInactiveRoutes: true
    }
);

const styles = StyleSheet.create({
    logo: {
        height: width*.3*.66,
        width: width*.3,
        // position: "absolute",
        alignSelf: "center",
        top: (viewport == Constants.ViewPort.High || viewport == Constants.ViewPort.Full)?64:16,
        marginBottom: (viewport == Constants.ViewPort.High || viewport == Constants.ViewPort.Full)?width*.16:width*.05
    },
    text: {
        color: '#70615a',
        fontFamily: 'YuMincho',
        fontSize: 15,

        paddingLeft: 10,
        paddingRight: 2
    }
});

export default createAppContainer(MainScreenRouter);



