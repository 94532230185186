/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { NavigationActions } from "react-navigation";
import { ImageBackground, Dimensions, StyleSheet, View, TouchableOpacity, Image} from 'react-native';
import { NativeBaseProvider } from "native-base";

const {width, height} = Dimensions.get('window');

import Styles from "./constants/Styles";
import Layout from "./constants/Layout";

import ScreenHeader from "./components/ScreenHeader";
import ActionButton from "./components/ActionButton";
import Entities from "./utils/Entities";

export default class TopScreen extends React.PureComponent {

    render() {
        console.log('Device: { width: '+ width + ', height: ' + height + '}');
        console.log('ViewPort: ' +  JSON.stringify(Entities.getViewPort(width, height)));

        return (
            <NativeBaseProvider style= {Styles.common.Container}>
                <StatusBar hidden/>
                <ImageBackground source={require('../assets/images/bg-screen-top.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={''} navigation={this.props.navigation} left={false}/>
                    <View style={styles.top}>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <Image style={[styles.logo, {marginLeft: width*.1}]} source={require('../assets/images/logo.png')}/>
                        </View>
                    </View>
                    <View style={styles.middle}>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <TouchableOpacity style={[styles.controls, {marginLeft: 20}]} activeOpacity={.8} onPress={() => {
                                this.navigateToScreen('TodayStart');
                            }}>
                                <Image style={styles.btnTop} source={require('../assets/images/btn-td-message.png')}/>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <TouchableOpacity style={[styles.controls, {marginLeft: 20}]} activeOpacity={.8} onPress={() => {
                                this.navigateToScreen('BirthdayStart');
                            }}>
                                <Image style={styles.btnTop} source={require('../assets/images/btn-bd-ft.png')}/>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 1, flexDirection: 'row'}}>
                            <TouchableOpacity style={[styles.controls, {marginLeft: 20}]} activeOpacity={.8} onPress={() => {
                                this.navigateToScreen('AdviceStart');
                            }}>
                                <Image style={styles.btnTop} source={require('../assets/images/btn-advice-msg.png')}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={styles.bottom}>
                        <ActionButton navigation={this.props.navigation} title={'監修者について'} action={'Supervisor'}/>
                        <ActionButton navigation={this.props.navigation} title={'平安絵巻タロットについて'} action={'About'}/>
                    </View>
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    /**
     *
     * @param name
     */
    navigateToScreen(name){
        const navigationAction = NavigationActions.navigate({
            routeName: name,
            params: {
                reset: true
            }
        });
        this.props.navigation.dispatch(navigationAction);
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    top: {
        backgroundColor: 'transparent',

        width:  Layout.screen.width,
        height: Layout.screen.height*.1,
        top: Layout.screen.height*.04
    },
    logo: {
        width: width*.8,
        height: width*.8*.66,

        resizeMode: 'stretch',
    },
    btnTop: {
        width: width-40,
        height: (width-40)/4.4,

        resizeMode: 'stretch',
    },
    middle: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.38,

        position: 'absolute',
        bottom: Layout.screen.height*.18
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.15,

        position: 'absolute',
        bottom: 0
    },
    version: {
        textAlign: "left",
        color: '#fff',
        fontSize: 20,
        fontFamily: 'KouzanGyousho',

        textShadowColor: 'black',
        textShadowRadius: 3,
        textShadowOffset: {width: 2, height: 2}
    },
    controls: {
        width: width-40,
        height: (width-40)/5.94
    }
});