/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import {StyleSheet, View, Image} from 'react-native';
import FlipCard from 'react-flipcard-2';

import Layout from "../constants/Layout";
import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

export default class TarotFlipCard extends React.PureComponent {

    constructor (props) {
        super(props);
        this.state = {
            flip: false
        }
    }

    componentDidMount() {
        if(!Entities.isEmpty(this.props.time)){
            const scope = this;
            setTimeout(() => {
                this.setState({flip: !scope.state.flip});

            }, this.props.time);
        }
    }

    /**
     *
     */
    render() {
        return (
             <View style={this.props.style}>
                 <FlipCard
                     disabled={true}
                     flipped={this.state.flip}
                     friction={30}
                     perspective={5000}
                     flipHorizontal={true}
                     flipVertical={false}
                     clickable={false}

                     alignHeight={true}
                     alignWidth={true}
                     onFlipEnd={(isFlipEnd)=>{
                         //@NOTE:
                     }}
                 >
                     {/* Face Side */}
                     <View style={styles.face}>
                         <Image style={Layout.flipCard}
                                source={require('../../assets/cards/card-back.png')}/>
                     </View>
                     {/* Back Side */}
                     <View style={styles.back}>
                         <Image style={ this.getCardStyle()} source={this.props.card.image}/>
                     </View>
                 </FlipCard>
             </View>
        );
    }

    getCardStyle(){
        const card = this.props.card;

        if(!Entities.isEmpty(card)){
            if (card.position == Constants.CardPosition.Reverse){
                return [Layout.flipCard, styles.reverse];
            }
        }
        return [Layout.flipCard];
    }
}

const styles = StyleSheet.create({

    face: {
        flex: 1,

        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',

        shadowOffset: { width: 6, height: 6 },
        shadowColor: 'rgba(128, 128, 128, .5)',
        shadowOpacity:.5,
        shadowRadius: 3,

        elevation: 6
    },
    back: {
        flex: 1,

        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center',

        shadowOffset: { width: 6, height: 6 },
        shadowColor: 'rgba(128, 128, 128, .5)',
        shadowOpacity:.5,
        shadowRadius: 3,

        elevation: 6
    },

    reverse: {
        transform: [{ rotate: '180deg' }]
    }
});