/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, Dimensions, StyleSheet, View, Text, Image} from 'react-native';
import {NativeBaseProvider} from "native-base";

const {width} = Dimensions.get('window');

import Layout from "./constants/Layout";
import ScreenHeader from "./components/ScreenHeader";
import ActionButton from "./components/ActionButton";

const message = 'タロットが大好きでタロット占いを始めてから15年以上経ちますが、いつもタロットカードは西洋のもの、海外のものという認識でした。\n' +
    '毎日目まぐるしく世界の状況が変わり、世界規模で大きな出来事が起こる昨今。\n' +
    'こんな時代だからこそ、私達のルーツである日本の文化を大切にしたい。\n' +
    '魂に触れるスピリチュアルの源泉である古代日本、中でも平安の優雅な世界をタロットという独特の世界で表現してみたいと思いました。\n' +
    '通常のタロットを知っている人も知らない人も、平安絵巻タロットの一枚一枚から語りかけるように平安の世界観を通して様々な気付きを得ることでしょう。\n' +
    '占いのツールとしてはもちろん、この美しい平安絵巻タロットの世界から今貴方が必要とするパワーやサポートを受け取っていただけると幸いです。\n';

export default class AboutScreen extends React.PureComponent {

    render() {
        return (
            <NativeBaseProvider>
                    <StatusBar hidden/>
                    <ImageBackground source={require('../assets/images/bg-screen.png')} resizeMode='stretch'
                                     style={{width: '100%', height: '100%'}}>
                        <ScreenHeader title={'平安絵巻タロットについて'} navigation={this.props.navigation}/>
                        <View style={styles.middle}>
                            <Image source={require('../assets/images/panel-supervisor.png')}
                                   style={styles.panel}/>
                            <Text style={styles.message}>{message}</Text>
                        </View>
                        <View style={styles.bottom}>
                            <ActionButton navigation={this.props.navigation} light title={'トップへ戻る'} action={'Top'}/>
                        </View>
                    </ImageBackground>
            </NativeBaseProvider>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        width:  Layout.screen.width,
        height: Layout.screen.height*.6,

        top: Layout.screen.height*.1,
    },
    panel: {
        width:  Layout.screen.width*.846,
        height: Layout.screen.width*.846*1.535,
    },
    message: {
        fontFamily: 'YuMincho',

        position: 'absolute',
        fontSize: width*.4*.33*.25,

        color: '#70615A',

        width:  Layout.screen.width*.72,
        height: Layout.screen.width*.78*1.535,

        lineHeight: width*.4*.33*.5,
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.1,

        position: 'absolute',
        bottom: 0
    }
});