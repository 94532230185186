/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, StyleSheet, View, Image, Dimensions, Text } from 'react-native';
import { NavigationEvents } from "react-navigation";
import { NativeBaseProvider } from "native-base";
import ModalDropdown from 'react-select'

import Layout from "./../constants/Layout";

import ScreenHeader from "../components/ScreenHeader";
import ScreenHint from "../components/ScreenHint";
import ActionButton from "../components/ActionButton";
import Constants from "../constants/Constants";
import BirthdayCardsAPI from "../api/BirthdayCardsAPI";

import Entities from "../utils/Entities";

const {width, height} = Dimensions.get('window');

export default class BirthdayFortuneTellingStart extends React.Component {

    constructor(props){
        super(props);

        this.state = {
        }
    }

    render() {
        var years = [];
        for (var i = 2016; i > 1911; i--) {
            years.push({ value: i + '', label: i});
        }

        var months = [];
        for (var i = 1; i <= 12; i++) {
            months.push({ value: i + '', label: i});
        }

        var days = [];
        for (var i = 1; i <= 31; i++) {
            days.push({ value: i + '', label: i});
        }

        return (
            <NativeBaseProvider>
                <NavigationEvents onDidBlur={() => {
                    this.setState({card: null});
                }}/>

                <StatusBar hidden/>
                <ImageBackground source={require('../../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={'生年月日で占う'} navigation={this.props.navigation}/>
                    <View style={styles.middle}>
                        <ScreenHint text={'誕生日を入力し、貴方を導くカードを知る'}/>
                        <View style={styles.year}>
                            <Image style={styles.picker} source={require('../../assets/images/picker-date.png')}/>
                            <ModalDropdown isSearchable={false} clearable = {true}
                                           placeholder=' 年 '
                                           options={years}
                                           menuPortalTarget={document.body}
                                           styles={{
                                               control: provided => ({ ...provided, background: '#fdf5df', boxShadow: 'none'}),
                                               menu: provided => ({ ...provided, zIndex: 5, color: '#70615A', background: '#fdf5df'})
                                           }}
                                           onChange = {(value)=> {
                                               this.setState({year: value.label});
                                           }} />
                            <Text style={styles.label}>{'年'}</Text>
                        </View>
                        <View style={styles.month}>
                            <Image style={styles.picker} source={require('../../assets/images/picker-date.png')}/>
                            <ModalDropdown isSearchable={false} clearable = {true}
                                           placeholder=' 月 '
                                           options={months}
                                           menuPortalTarget={document.body}
                                           styles={{
                                               control: provided => ({ ...provided, background: '#fdf5df', boxShadow: 'none'}),
                                               menu: provided => ({ ...provided, zIndex: 5, color: '#70615A', background: '#fdf5df'})
                                           }}
                                           onChange = {(value)=> {
                                               this.setState({month: value.label});
                                           }} />
                            <Text style={styles.label}>{'月'}</Text>
                        </View>
                        <View style={styles.day}>
                            <Image style={styles.picker} source={require('../../assets/images/picker-date.png')}/>
                            <ModalDropdown isSearchable={false} clearable = {true}
                                           placeholder=' 日 '
                                           options={days}
                                           menuPortalTarget={document.body}
                                           styles={{
                                               control: provided => ({ ...provided, background: '#fdf5df', boxShadow: 'none'}),
                                               menu: provided => ({ ...provided, zIndex: 5, color: '#70615A', background: '#fdf5df'})
                                           }}
                                           onChange = {(value)=> {
                                               this.setState({day: value.label});
                                           }} />
                            <Text style={styles.label}>{'日'}</Text>
                        </View>
                    </View>
                    {
                        this.showResultButton() &&
                        <View style={styles.bottom}>
                            <ActionButton navigation={this.props.navigation} title={'結果を見る'} action={'BirthdayResult'} data={this.getCard()}/>
                        </View>
                    }
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    showResultButton(){
        return !Entities.isEmpty(this.state.year) && !Entities.isEmpty(this.state.month) && !Entities.isEmpty(this.state.day);
    }

    /**
     *
     */
    getCard(){

        if(Entities.isEmpty(this.state.year) || Entities.isEmpty(this.state.month) || Entities.isEmpty(this.state.day)){
            return null;
        }

        const number = parseInt(this.state.year + Entities.padNumber(this.state.month) + Entities.padNumber(this.state.day), 10);
        console.log('parseInt: ' + number);

        const result = BirthdayCardsAPI.get(number%Constants.CARDS_BIRTHDAY_TOTAL + 1);
        if(!result.success){
            return {};
        }
        return result.data;
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        width:  Layout.screen.width,
        height: Layout.screen.height*.8,

        position: 'absolute',
        bottom: Layout.screen.height/16
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.1,

        position: 'absolute',
        bottom: 0
    },
    year: {
        position: 'absolute',
        top: height*.150,

        width: width*.444,
        height: width*.444*.335
    },
    month: {
        position: 'absolute',
        top: height*.250,

        width: width*.444,
        height: width*.444*.335
    },
    day: {
        position: 'absolute',
        top: height*.350,

        width: width*.444,
        height: width*.444*.335
    },
    dropdown: {
        position: 'absolute',
        top: 5,

        width: width*.444,
        height: width*.444*.335,

        fontSize: 16,
        textAlign: 'center',
        color: '#70615A'
    },
    dropdowntext: {
        paddingTop: 5,
        width: width*.444,
        height: width*.444*.2,

        fontSize: 16,
        textAlign: 'center',
        color: '#70615A'
    },
    label: {
        position: 'absolute',
        top: 8,
        width: width*.1,
        textAlign: 'center',

        left: width*.45,
        fontSize: 16,
        color: '#70615A'
    },
    picker: {
        display: 'none',
        width: width*.444,
        height: width*.444*.335
    }
});