/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

import ErrorCodes from './ErrorCodes';
import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

const LoveAdviceCardsAPI = {

    cards: null,

    /**
     *
     * @param code
     * @returns {{success: boolean}}
     */
    get(no){
        var result = {success: true, data: null};

        if(Entities.isEmpty(this.cards)){
            this.init();
        }

        if(Entities.isEmpty(no)){
            if(this.cards.length != 0){
                result.data = this.cards[0];
            }
        }else {
            result.data = this.cards.find(card => card.no === no);
        }

        if(Entities.isEmpty(result.data)){
            result.success = false;
            result.error = ErrorCodes.ERROR_CARD_NOT_FOUND;
        }

        return result;
    },

    saveLocalStorage() {
        // this.saveCards();
    },

    loadLocalStorage() {
        // this.cards =  this.loadCards();
    },

    async saveCards() {
        try {
            await AsyncStorage.setItem('cards', JSON.stringify(this.cards));
        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
    },

    async loadCards() {
        let guidebook = '';
        try {
            guidebook = await AsyncStorage.getItem('cards') || 'none';
            return JSON.parse(guidebook);

        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
        return null;
    },

    init(){
        this.loadLocalStorage();

        if (Entities.isEmpty(this.cards)){

            this.cards = [
                {
                    no: 1,
                    title: '風流人',
                    image: require('../../assets/cards/01.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: 'いつも自由でいたい貴方。恋愛でも相手に束縛されたり行動の自由を奪われると感じる関係は苦手なので、自分のスペースや一人になれる時間を大切にします。パートナーとも、お互いのパーソナルスペースを大事にできれば長続きできそうです。'
                },
                {
                    no: 2,
                    title: '陰陽師',
                    image: require('../../assets/cards/02.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '相手の気持ちや行動に敏感で、察することが得意な貴方。そんな気遣いが相手を安心させることもありますが、相手を優先しすぎて疲れてしまうことも。時にはワガママを言えるリラックスした関係を目指しましょう。'
                },
                {
                    no: 3,
                    title: '斎宮',
                    image: require('../../assets/cards/03.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '素直で純粋な貴方。好きになると相手のことが気になって自分の気持ちばかりが高まってしまいがち。行動できずに片想いの期間が長くなってしまいがちなので、意識して話しかけたり行動することが大切です。'
                },
                {
                    no: 4,
                    title: '皇后',
                    image: require('../../assets/cards/04.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '外見的な魅力に溢れ異性からのアプローチも多い貴方。モテることは素敵なことですが、恋愛対象を一人に絞りきれないこともしばしば。計画が思い通りに進まない時には思い切って一人と向き合う恋愛をしてみるのも悪くないですよ。'
                },
                {
                    no: 5,
                    title: '帝',
                    image: require('../../assets/cards/05.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '人をまとめたりリーダーになることの多い貴方は、立場ゆえのプライドが邪魔をして恋愛に素直になれないところがあります。どんな些細な思いでも、言葉に出すことが恋愛の後押しとなります。リラックスして自然体の貴方でいることです。'
                },
                {
                    no: 6,
                    title: '大阿闍梨',
                    image: require('../../assets/cards/06.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '周りの意見にも耳を傾ける聞き上手な貴方。恋愛においても、そのコミュニケーション力が相手の心を開くきっかけとなることでしょう。カフェや散歩など二人で話す時間を大切にしてください。恋人募集中の貴方は、信頼できる友人や年長者から紹介してもらうのが良縁の秘訣です。'
                },
                {
                    no: 7,
                    title: '戀人',
                    image: require('../../assets/cards/07.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '恋愛の運氣がとても良い流れで巡ってきています。パートナーのいる人は、相手との関係性も安定してお互いを想いやれる心境に感謝して過ごしましょう。恋人募集中の貴方は、今一度自分の周りの人間関係を見回してみて。貴方に想いを向けている人に気付くはずです。'
                },
                {
                    no: 8,
                    title: '車',
                    image: require('../../assets/cards/08.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '会えない時間や遠距離が、お互いの気持ちを高めて恋愛を燃え上がらせてくれる運氣。たとえ遠く離れていても、気持ちの絆は強く結ばれているので心配はいりません。どうしても会いたい時には乗り物のパワーを味方につけること。スピード感や異空間の感覚が恋を動かす力になるでしょう。'
                },
                {
                    no: 9,
                    title: '武士',
                    image: require('../../assets/cards/09.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '力強く意地を張ってみても、心は繊細で傷つきやすい貴方。そんな貴方にはもっと心の優しい懐の深いパートナーがお似合いです。条件や外見が魅力的であるが故に本当に相性の良い人を見逃さないよう注意しましょう。'
                },
                {
                    no: 10,
                    title: '隠者',
                    image: require('../../assets/cards/10.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '恋愛に対して慎重で、いつも冷静な貴方。燃え上がるような恋に憧れつつも地に足のついた関係を作っていけるので周囲からの信頼は厚いようです。恋愛対象となる相手は、仲の良い友人や仕事仲間から発展すると自然体で長く続く縁を紡げそう。出会い系や合コンなど苦手と感じる場は避けてOKです。'
                },
                {
                    no: 11,
                    title: '四季',
                    image: require('../../assets/cards/11.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方にとって運命のパートナーとの出逢いが近づいています。または、もうすでに出逢っているかも！運命の始まりはドラマティックなものとは限りません。自分の日常で関わる人達を注意深く観察して、貴方が幸せを感じられる時間を大切にしましょう。'
                },
                {
                    no: 12,
                    title: '勧善懲悪',
                    image: require('../../assets/cards/12.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '誠実な相手との対等な恋愛を好む貴方。貴方には不倫や浮気など自分や誰かを傷つけるような恋愛は向きません。万一、そのような誘いにあったり巻き込まれそうになったなら流されずにきちんと意思表示をすることが大切です。'
                },
                {
                    no: 13,
                    title: '流刑者',
                    image: require('../../assets/cards/13.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '相手のことを思いやるからこそ、傷ついたり悲しみを感じたりするのがこの恋なのかもしれません。時には自分の気持ちに正直になって自分の想いをぶつけてみても良いのです。「良い人」を辞めると相手に対する理解が深まります。'
                },
                {
                    no: 14,
                    title: '閻魔',
                    image: require('../../assets/cards/14.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '腐れ縁の関係を精算する時期が近づいています。好きでもないのに一緒にいたり、生活のために我慢をするような関係は今の貴方には相応しくありません。思い切って新しい一歩を踏み出して、新しい縁を引き寄せましょう。'
                },
                {
                    no: 15,
                    title: '宴',
                    image: require('../../assets/cards/15.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '新しい出会いを探すなら、自然の中が一番。ハイキングやマリンスポーツなど自然を共に感じられる環境で、沢山の人たちと楽しみながらグループで仲良くなると、その中から長く付き合える相手が見つかりそうです。'
                },
                {
                    no: 16,
                    title: '般若',
                    image: require('../../assets/cards/16.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の外見的な要素や置かれた状況から、思いがけない人から嫉妬心を向けられたり羨ましがられている様子。貴方に罪はないので気にせず自分の恋愛に集中しましょう。相手の立場や条件に執着しなければ良いご縁に繋がります。'
                },
                {
                    no: 17,
                    title: '雷',
                    image: require('../../assets/cards/17.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '気になる人との縁が突然離れたり、または急接近する暗示。どちらにしても、今の曖昧な関係からは脱出するので現実的な変化としては好転と捉えて良いです。大切なことはパニックにならずこのタイミングをチャンスに変えること。感情的になると物事がうまく進まないので注意が必要です。'
                },
                {
                    no: 18,
                    title: '望月',
                    image: require('../../assets/cards/18.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今想いを寄せる人がいるなら、理想の人と理想通りの恋愛関係が作れそうな暗示。パートナーがいる人は、今気になっていることを話し合って改善しようと前向きな言動をすることでより心地よい関係性が築けるでしょう。'
                },
                {
                    no: 19,
                    title: '闇夜',
                    image: require('../../assets/cards/19.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '気になっている相手について、不信感や不安感がふと浮かんできたら、そのまま流してしまうのではなく一度紙に書き出してみましょう。今は不安感や不信感を明確にして自分自身が安心を得ることの方が大切です。'
                },
                {
                    no: 20,
                    title: '祭',
                    image: require('../../assets/cards/20.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '喜びと情熱に溢れる関係性から、実りのある恋愛・結婚が近づいています。もしお付き合いを順調に進めたいようなら、相手の家族や親戚との関係も意識してみること。周囲からも応援されるご縁であり、子宝に恵まれるのも早そうです。'
                },
                {
                    no: 21,
                    title: '出家',
                    image: require('../../assets/cards/21.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '長い片思いや失恋の痛みからは、そろそろ卒業する時です。昔の想いに固執してしまうと新しい素敵な縁や出会いを見落としてしまうので、思い切って部屋の断捨離や思い出の品を手放すなど身の回りのエネルギーを一新してみましょう。'
                },
                {
                    no: 22,
                    title: '極楽浄土',
                    image: require('../../assets/cards/22.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今までの努力が実り、二人の関係には一応の決着や答えが出そうです。しかし、これはゴールではなく新しいスタート。ここからまた新鮮な気持ちで相手への信頼を積み重ねていく必要があります。記念日にはお気に入りのワインやシャンパンでお祝いの気持ちを持つことが良いパートナーシップの秘訣です。'
                },
            ]
            this.saveLocalStorage();
        }
    }
};

// LoveAdviceCardsAPI.init();
export default LoveAdviceCardsAPI;
