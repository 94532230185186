/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, StyleSheet, View, Image } from 'react-native';
import { NativeBaseProvider } from "native-base";

import Layout from "./../constants/Layout";
import ActionButton from "./../components/ActionButton";

import ScreenHeader from "../components/ScreenHeader";
import TarotFlipCard from "../components/TarotFlipCard";
import Constants from "../constants/Constants";
import CardsAPI from "../api/CardsAPI";

export default class TodayFortuneTellingProcess extends React.Component {

    constructor(props) {
        super(props);

        var cards = [];
        while (cards.length != Constants.CARDS_TODAY_SHUFFLE){
            var candidate = this.getCard();

            if(cards.find(card => {
                const pairNo = (candidate.no%2 == 0)?candidate.no-1: candidate.no+1;
                return (card.no === candidate.no) || (card.no == pairNo);
            }) == null){
                cards.push(candidate);
            }
        }
        this.state = {cards: cards, complete: false};
    }

    /**
     *
     */
    render() {

        setTimeout(() => {
            this.setState({complete: true});
        }, Constants.SHOW_RESULT_TIMEOUT);

        const cards = this.state.cards;
        return (
            <NativeBaseProvider>
                <StatusBar hidden/>
                <ImageBackground source={require('../../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={'今日のメッセージ'} navigation={this.props.navigation} left={false}/>
                    <View style={styles.middle}>
                        <Image source={require('../../assets/images/card-hexagram.png')}
                               style={styles.hexagram}/>
                        <TarotFlipCard style={styles.flip1} card={cards[0]} time= {500}/>
                        <TarotFlipCard style={styles.flip2} card={cards[1]} time={1500}/>
                        <TarotFlipCard style={styles.flip3} card={cards[2]} time={2500}/>
                        <TarotFlipCard style={styles.flip4} card={cards[3]} time={3500}/>
                        <TarotFlipCard style={styles.flip5} card={cards[4]} time={4500}/>
                        <TarotFlipCard style={styles.flip6} card={cards[5]} time={5500}/>
                        <TarotFlipCard style={styles.flip7} card={cards[6]} time={6500}/>
                    </View>
                    {
                        this.state.complete &&
                        <View style={styles.bottom}>
                            <ActionButton navigation={this.props.navigation} title={'結果をみる'} action={'TodayResult'} data={cards[3]}/>
                        </View>
                    }
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    /**
     *
     */
    getCard(){
        const result = CardsAPI.get(Math.floor(Math.random() * Constants.CARDS_TOTAL) + 1);
        if(!result.success){
            return {};
        }

        return result.data;
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        width:  Layout.screen.width,
        height: Layout.screen.height*.7,

        position: 'absolute',
        bottom: Layout.screen.height/10
    },
    hexagram: {
        position: 'absolute',
        top: Layout.screen.width*.63*.2,

        width: Layout.screen.width*.63,
        height: Layout.screen.width*.63*1.13
    },
    flip1: {
        position: 'absolute',

        top: 0,
        left: Layout.screen.width*.5 - Layout.flipCard.width/2,
    },
    flip2: {
        position: 'absolute',

        top: Layout.screen.width*.63*.2,
        left: Layout.screen.width*.17 - Layout.flipCard.width/2,
    },
    flip3: {
        position: 'absolute',

        top: Layout.screen.width*.63*.2,
        left: Layout.screen.width*.835 - Layout.flipCard.width/2,
    },
    flip4: {
        position: 'absolute',

        top: Layout.screen.width*.63*.56,
        left: Layout.screen.width*.5 - Layout.flipCard.width/2,
    },
    flip5: {
        position: 'absolute',

        top: Layout.screen.width*.63*.92,
        left: Layout.screen.width*.17 - Layout.flipCard.width/2,
    },
    flip6: {
        position: 'absolute',

        top: Layout.screen.width*.63*.92,
        left: Layout.screen.width*.835 - Layout.flipCard.width/2,
    },
    flip7: {
        position: 'absolute',

        top: Layout.screen.width*.63*1.13,
        left: Layout.screen.width*.5 - Layout.flipCard.width/2,
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.1,

        position: 'absolute',
        bottom: 0
    },
});
