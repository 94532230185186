/**
 *
 *  Copyright 2022 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";

import { ImageBackground, Dimensions, StyleSheet, View, Image, Text} from 'react-native';
import {StatusBar} from "expo-status-bar";
import { NativeBaseProvider } from "native-base";

import AppLoading from 'expo-app-loading';
import * as Font from 'expo-font';
import {Asset} from 'expo-asset';

import ApplicationRouter from './src/ApplicationRouter';

import Constants from './src/constants/Constants';
import Styles from "./src/constants/Styles";
import Layout from "./src/constants/Layout";

const {width, height} = Dimensions.get('window');

export default class App extends React.Component {

    constructor() {
        super();

        const orientation = (Layout.screen.width > Layout.screen.height)?Constants.Orientation.Landscape:Constants.Orientation.Portrait;
        console.log('Orientation: ' + orientation);
        this.state = {
            isReady: false,
            orientation: orientation
        };
    }

    async componentDidMount() {
        const scope = this;

        window.addEventListener("orientationchange", function() {
            if ( window.orientation == 0 || window.orientation == 180) {
                // Device rotated in the portrait mode
                // setTimeout(() => {
                //     scope.setState({orientation: Constants.Orientation.Portrait});
                // }, 1000);
            } else {
                // Device rotated in the landscape mode
                // scope.setState({orientation: Constants.Orientation.Landscape});
            }
            scope.reload();
        }, false);

    }

    /**
     *
     */
    reload(){
        location.reload();
    }

    render() {

        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) == false){
            const scope = this;
            window.addEventListener('resize', function() {
                scope.reload();
            });

            return (
                <NativeBaseProvider style= {Styles.common.Container}>
                    <ImageBackground source={require('./assets/images/bg-screen-landscape.png')} resizeMode='stretch'
                                     style={{width: '100%', height: '100%'}}>
                        <View style={styles.bottom}>
                            <View style={styles.container}>
                                <Text style={styles.hint}>このコンテンツはスマホでご覧ください。</Text>
                            </View>
                        </View>
                    </ImageBackground>
                </NativeBaseProvider>
            );
        }

        if (!this.state.isReady) {
            return <AppLoading
                startAsync={this._loadResourcesAsync}
                onError={this._handleLoadingError}
                onFinish={this._handleFinishLoading}
            />;
        }

        if(this.state.orientation == Constants.Orientation.Portrait){
            return (
                <ApplicationRouter/>
            );
        }
        return (
            <NativeBaseProvider style= {Styles.common.Container}>
                <ImageBackground source={require('./assets/images/bg-screen-landscape.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>
                    <View style={styles.bottom}>
                        <View style={styles.container}>
                            <Text style={styles.hint}>スマホを縦方向にしてご覧ください。</Text>
                        </View>
                    </View>
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    _loadResourcesAsync = async () => {

        await Font.loadAsync({
            KouzanGyousho: require("./assets/fonts/Kouzan-Gyousho.ttf"),
            YuMincho: require("./assets/fonts/Yu-Mincho.ttf")
        });

        await Asset.loadAsync(
            [
                require('./assets/images/bg-screen.png'),
                require('./assets/images/bg-screen-hint.png'),
                require('./assets/images/bg-screen-landscape.png'),
                require('./assets/images/bg-screen-top.png'),

                require('./assets/images/bg-top-screen.png'),

                require('./assets/images/btn-action.png'),
                require('./assets/images/btn-action-light.png'),
                require('./assets/images/btn-action-small.png'),

                require('./assets/images/btn-back.png'),
                require('./assets/images/btn-menu.png'),

                require('./assets/images/btn-bd-ft.png'),
                require('./assets/images/btn-td-message.png'),
                require('./assets/images/btn-advice-msg.png'),

                require('./assets/images/card-hexagram.png'),
                require('./assets/images/card-result.png'),

                require('./assets/images/logo.png'),

                require('./assets/images/panel-advice.png'),
                require('./assets/images/panel-supervisor.png'),
                require('./assets/images/picker-date.png'),

                require('./assets/cards/01.jpg'),
                require('./assets/cards/02.jpg'),
                require('./assets/cards/03.jpg'),
                require('./assets/cards/04.jpg'),
                require('./assets/cards/05.jpg'),
                require('./assets/cards/06.jpg'),
                require('./assets/cards/07.jpg'),
                require('./assets/cards/08.jpg'),
                require('./assets/cards/09.jpg'),
                require('./assets/cards/10.jpg'),
                require('./assets/cards/11.jpg'),
                require('./assets/cards/12.jpg'),
                require('./assets/cards/13.jpg'),
                require('./assets/cards/14.jpg'),
                require('./assets/cards/15.jpg'),
                require('./assets/cards/16.jpg'),
                require('./assets/cards/17.jpg'),
                require('./assets/cards/18.jpg'),
                require('./assets/cards/19.jpg'),
                require('./assets/cards/20.jpg'),
                require('./assets/cards/21.jpg'),
                require('./assets/cards/22.jpg'),

                require('./assets/cards/card-back.png'),

                require('./assets/animations/day.jpg')
            ]);
    };

    _handleLoadingError = error => {
        // In this case, you might want to report the error to your error
        // reporting service, for example Sentry
        console.warn(error);
    };

    _handleFinishLoading = () => {
        this.setState({isReady: true});
    };
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.2,

        position: 'absolute',
        bottom: Layout.screen.height*.2,
    },
    hint: {
        fontFamily: 'YuMincho',
        fontSize: Layout.screen.height*.1*.5,
        color: '#70615A'
    }
});
