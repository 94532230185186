/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, StyleSheet, View, Image, Dimensions } from 'react-native';
import { NativeBaseProvider } from "native-base";

import Layout from "./../constants/Layout";

import ScreenHeader from "../components/ScreenHeader";
import ScreenHint from "../components/ScreenHint";
import ActionButton from "../components/ActionButton";

const {width, height} = Dimensions.get('window');

export default class TodayFortuneTellingStart extends React.PureComponent {

    render() {

        return (
            <NativeBaseProvider>
                <StatusBar hidden/>
                <ImageBackground source={require('../../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={'今日のメッセージ'} navigation={this.props.navigation}/>
                    <View style={styles.middle}>
                        <ScreenHint text={'心静かに今日のメッセージを受け取る'}/>
                        <View style={styles.card}>
                            <Image source={require('../../assets/cards/card-back.png')}
                                   style={styles.image}/>
                        </View>
                    </View>
                    <View style={styles.bottom}>
                        <ActionButton navigation={this.props.navigation} title={'占いを始める'} action={'TodayShuffle'}/>
                    </View>
                </ImageBackground>
            </NativeBaseProvider>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    card: {
        position: 'absolute',
        top: Layout.screen.height*.15,

        width: Layout.screen.width*.427,
        height: Layout.screen.width*.427*1.72,

        shadowColor: '#000',
        shadowOpacity: 0.3,
        shadowRadius: 10,
        shadowOffset: { width: 10, height: 10}
    },
    image: {
        position: 'absolute',
        top: 0,

        width: Layout.screen.width*.427,
        height: Layout.screen.width*.427*1.72
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        position: 'absolute',

        width:  Layout.screen.width,
        height: Layout.screen.height*.8,

        bottom: Layout.screen.height/16
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.1,

        position: 'absolute',
        bottom: 0
    }
});