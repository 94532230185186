/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

import ErrorCodes from './ErrorCodes';
import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

const BirthdayCardsAPI = {

    cards: null,

    /**
     *
     * @param code
     * @returns {{success: boolean}}
     */
    get(no){
        var result = {success: true, data: null};

        if(Entities.isEmpty(this.cards)){
            this.init();
        }

        if(Entities.isEmpty(no)){
            if(this.cards.length != 0){
                result.data = this.cards[0];
            }
        }else {
            result.data = this.cards.find(card => card.no === no);
        }

        if(Entities.isEmpty(result.data)){
            result.success = false;
            result.error = ErrorCodes.ERROR_CARD_NOT_FOUND;
        }

        return result;
    },

    saveLocalStorage() {
        // this.saveCards();
    },

    loadLocalStorage() {
        // this.cards =  this.loadCards();
    },

    async saveCards() {
        try {
            await AsyncStorage.setItem('cards', JSON.stringify(this.cards));
        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
    },

    async loadCards() {
        let guidebook = '';
        try {
            guidebook = await AsyncStorage.getItem('cards') || 'none';
            return JSON.parse(guidebook);

        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
        return null;
    },

    init(){
        this.loadLocalStorage();

        if (Entities.isEmpty(this.cards)){

            this.cards = [
                {
                    no: 1,
                    title: '風流人',
                    image: require('../../assets/cards/01.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「風流人」のカード。周囲の意見や状況にとらわれず、常に自分らしさを追求し正直に生きるパワーを与えてくれます。真の「風流＝優雅さや上品さのある美しさ」とは、自分自身の内なる強さや自信によって形作られていきます。このカードは、どんなに困難な状況であっても貴方が貴方らしさを貫くことの大切さを教えてくれるのです。'
                },
                {
                    no: 2,
                    title: '陰陽師',
                    image: require('../../assets/cards/02.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「陰陽師」のカード。目に見えない力や存在と対話し、時には自分の味方として社会を生きるための強さと賢さを貴方に与えてくれます。「目に見えない力」とは、日々の何気ない経験の積み重ねとともに他者や自然への想いやりによって形作られるもの。「陰陽師」は貴方の人生を大きく動かすための力と素養を育ててくれることでしょう。'
                },
                {
                    no: 3,
                    title: '斎宮',
                    image: require('../../assets/cards/03.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方を導くの「斎宮」のカード。純真で無垢、素直で繊細な貴方が、強く誇りを持って生きることをこのカードは助けてくれることでしょう。斎宮とは、国の平和を願い神に仕える巫女として派遣された高貴な女性です。このカードに込められた平和と幸せへの祈りが、貴方の優しさを必要とする環境へと導いてくれることでしょう。'
                },
                {
                    no: 4,
                    title: '皇后',
                    image: require('../../assets/cards/04.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「皇后」のカード。全ての母親、または母性の象徴として弱い者や幼い者たちを育て慈しむ力を与えてくれるカードです。この世界の全ては母の愛から始まると言っても過言ではありません。「産み出す」ことは「豊かさ」そのもの。そんな豊かな愛の象徴であるこのカードは、不安に苛まれたときいつでも貴方を安心感で満たしてくれることでしょう。'
                },
                {
                    no: 5,
                    title: '帝',
                    image: require('../../assets/cards/05.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方を導くのは「帝」のカード。国を治めるリーダーとして古より多くの人々を従えてきたこのカードのパワーは、貴方にリーダーとして人の上に立ち、人々を導く強さを与えてくれます。人を導くということは迷える者や不安に怯える者達を救い助けるということでもあります。貴方が本来持っている人を導く才能を発揮し、多くの人の助けとなるようこのカードは貴方に必要なメッセージを語りかけてくれるでしょう。'
                },
                {
                    no: 6,
                    title: '大阿闍梨',
                    image: require('../../assets/cards/06.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「大阿闍梨」のカード。仏の御心とその言葉を人々に伝えるために厳しい修行を続けながら、どんな人々の悩みにも耳を傾ける謙虚さと慈愛に満ちているこのカードは、聞き上手で他者への思いやりの深い貴方が、人から悩みを相談されたりアドバイスを求められたときに貴方に寄り添い、貴方が必要とする助けを与えてくれるでしょう。'
                },
                {
                    no: 7,
                    title: '戀人',
                    image: require('../../assets/cards/07.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「戀人（恋人）」のカード。戀とは、本来想いを寄せる相手の幸せを願うこと。そのお互いの想いが引き寄せられる糸のように出逢い、発する言霊を通して強く結ばれることを縁結びと言います。このカードは、願いを叶えるための「想い」と「言霊」双方の力を与えてくれるお守りのようなカード。様々な恋愛を通して貴方をより魅力的に成長させてくれることでしょう。'
                },
                {
                    no: 8,
                    title: '車',
                    image: require('../../assets/cards/08.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「車」のカード。考えるよりもまずは行動することで現実を動かす事が得意な貴方。貴方の行動力がそのまま勉学や仕事に対する周囲からの評価となり、周りからの信頼も厚いことでしょう。このカードは貴方に自分の意思で人生の舵取りをすることの大切さを教え、余裕を持って過ごせるよう導いてくれることでしょう。人生は緩急。時にはゆったり休息を取ることも大切です。'
                },
                {
                    no: 9,
                    title: '武士',
                    image: require('../../assets/cards/09.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「武士」のカード。周りからの外見の印象とは違って、とても繊細で細やかな気配りの多い貴方。このカードはそんな繊細な貴方が社会で上手く生きていけるよう常に守り助けてくれることでしょう。本来、武士の携えている刀は人を斬るためではなく、弱い者に近づく不穏なエネルギーを断ち切るものであると言われています。このカードは貴方が戦うためではなく、繊細な自分を守るための力強さを与えてくれるでしょう。'
                },
                {
                    no: 10,
                    title: '隠者',
                    image: require('../../assets/cards/10.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「隠者」のカード。世の中の流行や人々の噂話など不確かなものに心を惑わされるのはこの世の常とは言いますが、この隠者のカードはそんな世の中を俯瞰して（冷静に）見るための視点を貴方に与えてくれることでしょう。必要以上に不安や恐れを抱くのではなく、一歩離れたところから世の中を見ることで今とは違った世界が見えることでしょう。貴方のその冷静さが人々の感情を鎮め、心に安心感と安らぎを与えることでしょう。'
                },
                {
                    no: 11,
                    title: '四季',
                    image: require('../../assets/cards/11.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「四季」のカード。季節の移ろいとともに花や風景の変化を楽しみ、慈しむ事ができる貴方は、自然界との繋がりが他の人より深いようです。自分の身の回りに植物や花を置いてみたり、庭で花を育てたりすると彼らから多くのパワーを受け取る事ができるでしょう。この四季のカードは、貴方が自然界からのメッセージを受け取れるようサポートします。'
                },
                {
                    no: 12,
                    title: '勧善懲悪',
                    image: require('../../assets/cards/12.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の人生を導くのは「勧善懲悪」のカード。不正や不平等を嫌い、心正しき行いとともに常に誠実であることを心掛ける貴方にとって、このカードは誠実な貴方を常に助けるパワーとなります。勧善懲悪には因果応報というもう一つの意味も込められています。自分自身が誠実であれば誠実さが周囲から返り、自身が不正を働くなら逆もまた然りと。その理を示すカードなのです。'
                },
                {
                    no: 13,
                    title: '流刑者',
                    image: require('../../assets/cards/13.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「流刑者」のカード。都での失脚によって地方へ追いやられる流刑者。失脚の原因が本人の罪か、周囲の周到な罠なのかは知る由もありません。しかしながら、この人物はとても晴れやかな表情をしています。理由はどうあれ、人々の欲望と画策の渦巻く都から離れられることへの安堵と新天地への希望があるのです。逃げることは決して悪いことではありません。このカードは、常に最善を求め、苦しい状況や辛い環境から脱却したいときに貴方の救いとなってくれることでしょう。'
                },
                {
                    no: 14,
                    title: '閻魔',
                    image: require('../../assets/cards/14.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「閻魔」のカード。人々が死んだ後、生前の行いに応じて天国と地獄に振り分けるという番人閻魔。閻魔が嘘つきの舌を切るという説話には背景があり、閻魔は嘘を吐いた者が負うカルマ（重責）を舌を切るという行為によって消すと言われています。一見すると悪人のように見える閻魔ですが、実はとても慈悲深い救済のカードでもあります。このカードは他者のために自分を犠牲にして相手に尽くすような慈愛深い心の持ち主を支え、その人へより深い愛と慈みを与えてくれるのです。'
                },
                {
                    no: 15,
                    title: '宴',
                    image: require('../../assets/cards/15.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「宴」のカード。日常のルーティーンを離れ、植物や水源など自然に囲まれた環境でゆったりと過ごしながら人々と語り合う宴。本来、宴とは自然の恵みを享受してそこに流れている水の涼しさや木々の芽吹きを愛でるもの。このカードは職場や家に篭もりがちな貴方に、自然溢れる環境が与えてくれるパワーを教えてくれます。'
                },
                {
                    no: 16,
                    title: '般若',
                    image: require('../../assets/cards/16.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方を助けてくれるのは「般若」のカード。「般若」は怒りや嫉妬、執着、悲しみの女性の面で描かれますが、仏教では「真理を見抜く仏の智慧」の象徴でもあります。もし、悲しみや怒りなどのネガティブな感情が湧き上がったなら、自分を責めるのではなく真理を見抜くための智慧として生じたその感情をしっかりと抱きしめてあげましょう。全ての感情には意味があり、無駄な感情はありません。このカードはネガティブな感情を抱えて苦しんでいる多くの人々の心を癒し、人生を幸運へと転じる助けとなることでしょう。'
                },
                {
                    no: 17,
                    title: '雷',
                    image: require('../../assets/cards/17.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「雷」のカード。突然思いもよらない出来事、ハプニングによって今まで当たり前であったことが変わらざるを得ないような、そんな人生のタイミングにおいて辛いショックを癒やし、むしろハプニングを好転機とするカードです。雷は「神鳴り」とも言われ、文字通り神の声であるとして敬意を表されてきました。このカードは人生の様々な転機においてのストレスを和らげ、貴方が自分らしく後悔のない人生を送るためのサポートとなることでしょう。'
                },
                {
                    no: 18,
                    title: '望月',
                    image: require('../../assets/cards/18.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「望月」のカード。欠けたところのない満月に、昔の人々は理想の成就や願望の達成を見出しました。このカードは、目標や理想が高く中途半端なことを嫌う貴方が、自分の望む理想や願望を達成し夢を叶えるための揺るぎない力を与えてくれることでしょう。満月の光は、暗い夜空を大きく照らす道標ですが、やがて貴方の理想も多くの人々の行く道を照らし導くものとなることでしょう。貴方が夢を叶えることが多くの人を助けることになるということを忘れないようにしてください。'
                },
                {
                    no: 19,
                    title: '闇夜',
                    image: require('../../assets/cards/19.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「闇夜」のカード。月の光の届かない新月。暗闇は深く、周囲が見えないからこそ多くの人々は不安な気持ちに襲われるものです。しかし、暗闇だからこそ感じられる美しさや輝きというのも存在しています。香道や音楽など、目では見る事ができない文化や芸術です。このカードは、貴方に視覚以外の五感を通して周囲を知ることの素晴らしさを教えてくれるでしょう。五感を発達させることがあなたの魅力を増すことにもなりそうです。'
                },
                {
                    no: 20,
                    title: '祭',
                    image: require('../../assets/cards/20.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「祭」のカード。太陽の下で繰り広げられる葵祭。子どもから大人まで、年に一度のこの非日常の空間を楽しみにしています。祭りは、日々の感謝と豊穣を神に感謝する催し。その行列に子ども達も参列するのは、子宝への感謝と祈りも込められているからに他なりません。このカードは、日々の生活の中で忘れがちな「喜び」と「新鮮さ」を思い出させ、新たな気持ちで楽しんで行動する力を貴方に与えてくれます。'
                },
                {
                    no: 21,
                    title: '出家',
                    image: require('../../assets/cards/21.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「出家」のカード。現世を捨て、仏門へ入る出家。それは現在の生き方を変えて新たなステージへ進むことです。このカードは、大きな決心を持って仕事や環境を変えようとするとき、貴方を力強く後押ししてくれることでしょう。周囲からの様々な声や意見があっても、貴方自身が決断し納得したことであるならそれは祝福すべきこと。自分の選択に自信を持って歩んでいくための助けとしてこのカードを思い出してください。'
                },
                {
                    no: 22,
                    title: '極楽浄土',
                    image: require('../../assets/cards/22.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の助けとなるのは「極楽浄土」のカード。一つの世界の完成。そして、物事の達成と成就を表すカードです。このカードは、物事を途中で挫けそうになったり、あきらめそうになった時に貴方の心に物事をやり遂げるための力を与えてくれることでしょう。本当の意味での「達成された世界」とは我慢や忍耐の世界ではありません。今よりも幸せを感じられる世界、そして平和な意識を持てる世界の成就こそが達成であり「極楽浄土」なのです。'
                },
            ]
            this.saveLocalStorage();
        }
    }
};

// BirthdayCardsAPI.init();
export default BirthdayCardsAPI;
