/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

import ErrorCodes from './ErrorCodes';
import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

const LoveAdviceCardsAPI = {

    cards: null,

    /**
     *
     * @param code
     * @returns {{success: boolean}}
     */
    get(no){
        var result = {success: true, data: null};

        if(Entities.isEmpty(this.cards)){
            this.init();
        }

        if(Entities.isEmpty(no)){
            if(this.cards.length != 0){
                result.data = this.cards[0];
            }
        }else {
            result.data = this.cards.find(card => card.no === no);
        }

        if(Entities.isEmpty(result.data)){
            result.success = false;
            result.error = ErrorCodes.ERROR_CARD_NOT_FOUND;
        }

        return result;
    },

    saveLocalStorage() {
        // this.saveCards();
    },

    loadLocalStorage() {
        // this.cards =  this.loadCards();
    },

    async saveCards() {
        try {
            await AsyncStorage.setItem('cards', JSON.stringify(this.cards));
        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
    },

    async loadCards() {
        let guidebook = '';
        try {
            guidebook = await AsyncStorage.getItem('cards') || 'none';
            return JSON.parse(guidebook);

        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
        return null;
    },

    init(){
        this.loadLocalStorage();

        if (Entities.isEmpty(this.cards)){

            this.cards = [
                {
                    no: 1,
                    title: '風流人',
                    image: require('../../assets/cards/01.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '独特の理想や崇高な意識を持っている貴方。周囲からは時々近寄り難いと思われることもありますが、それは結果的に良いことです。貴方の魅力を理解できない無理して一緒にいる必要がありません。自然体で過ごせる人や仲間と過ごす時間を大切にしましょう。'
                },
                {
                    no: 2,
                    title: '陰陽師',
                    image: require('../../assets/cards/02.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '真面目で人好きな貴方は、コミュニケーション上手で周囲からの人気者。社交的な性格でもあり、いつも周りには人が集まってきますが、皆に合わせようとしてやや八方美人になってしまうことも。全ての人に合わせる必要はないと肝に銘じておきましょう。'
                },
                {
                    no: 3,
                    title: '斎宮',
                    image: require('../../assets/cards/03.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '巷の流行や世間の話題にはそれほど興味を持てない貴方ですが、自分の好きなことや得意分野については他を圧倒する知識を持っています。周囲になんとなく話題を合わせるよりも、サークルやコミュニティで自分の好きなことを語れる友人を持つことが人生を楽しむ秘訣です。'
                },
                {
                    no: 4,
                    title: '皇后',
                    image: require('../../assets/cards/04.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '美味しいものや趣味の服・雑貨、子どもの話題などで幅広く人との繋がりを持てそうな時。今は広く浅くでも良いので沢山の人と繋がっておくのが吉。将来その中から特に仲の良い人や相談できるような信頼できる友人が現れます。'
                },
                {
                    no: 5,
                    title: '帝',
                    image: require('../../assets/cards/05.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '物事を他人から指図されたりアドバイスを受けるのがあまり好きではない貴方。決断するときは自分一人で決めることが多く、相談をあまり必要としない側面もありそうですが、時には信頼できる人の意見を聞いてみるのも良さそうです。'
                },
                {
                    no: 6,
                    title: '大阿闍梨',
                    image: require('../../assets/cards/06.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今、何か悩み事があるようなら自分一人で解決しないことです。貴方が信頼できる友人や知人、上司など誰でも良いので必ず２人以上の人に相談してみること。心が楽になるだけでなく、具体的な解決に導くヒントが見つかりそうです。'
                },
                {
                    no: 7,
                    title: '戀人',
                    image: require('../../assets/cards/07.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '異性の友達が今はとても助けになってくれる暗示。自分だけでは気付けないことを教えてくれたり、思わぬ指摘をしてくれたりとアドバイザーとしてとても頼りになる存在です。パートナーに相談をするなら、夜の落ち着いた時間に話すこと。二人の絆も深まります。'
                },
                {
                    no: 8,
                    title: '車',
                    image: require('../../assets/cards/08.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '仲間との信頼関係を深めたいと思ったら、車や電車などの乗り物での移動時間の会話が功を奏します。普段面と向かっては言えない相談事や相手への依頼事なども乗り物の中という環境が話をスムーズに運んでくれそうです。'
                },
                {
                    no: 9,
                    title: '武士',
                    image: require('../../assets/cards/09.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '人間関係に悩んだら、ペットや小動物とのふれあいの時間を持つことが大切だとカードは教えています。生き物に愛情を傾けることで自分自身の心が癒されてリラックスし、解決策を見出せるでしょう。'
                },
                {
                    no: 10,
                    title: '隠者',
                    image: require('../../assets/cards/10.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: 'メールやSNS、手紙など、文字でのコミュニケーションが今の人間関係には必要だとこのカードは教えてくれています。面と向かっては言いにくいことを文字でなら伝えられたり、感情的にならずに送ることができるのでその利点を生かしましょう。'
                },
                {
                    no: 11,
                    title: '四季',
                    image: require('../../assets/cards/11.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方には今、新しい人間関係や人脈を築くタイミングが来ているとこのカードは伝えています。普段の行動範囲から少し離れて今までとは違う環境に出掛けることをしばらくは意識してみてください。通勤や散歩のコースを変えるだけでも新たな出会いのきっかけになりそうです。'
                },
                {
                    no: 12,
                    title: '勧善懲悪',
                    image: require('../../assets/cards/12.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '他人の不正を思いがけず目にしたり、噂で聞いて嫌な気分になることも。しかし、相手のことをどんなに許せないと感じても、貴方が相手を裁く必要はありません。全ては因果応報。不正のエネルギーはそのまま本人へ返るので貴方は距離を置いて巻き込まれないようにすることです。'
                },
                {
                    no: 13,
                    title: '流刑者',
                    image: require('../../assets/cards/13.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '周囲の人へ思いやりの気持ちを持つことは良いですが、貴方自身が犠牲になったり自分を後回しにする気遣いでは決して良い人間関係を築くことはできません。貴方自身の主張や気持ちも大切にしつつ、貴方への理解を示してくれる人を大切にしてください。'
                },
                {
                    no: 14,
                    title: '閻魔',
                    image: require('../../assets/cards/14.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '何事も他人任せではなく、自分で責任を持って行動することの重要性を学びましょう。簡単なところからで良いので、自分で決めて自分で行動すること、そして自分で責任を取るという一連の流れを経験し、体感として理解するのです。他者への感謝の思いが自然と湧き上がってきます。'
                },
                {
                    no: 15,
                    title: '宴',
                    image: require('../../assets/cards/15.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今は浅く広く人間関係を作るのに適した時期。今までの限られた付き合いから少し視野を広げて、興味のある趣味のサークルや勉強会などに顔を出して沢山の人と交流しましょう。コミュニケーション能力が高まります。'
                },
                {
                    no: 16,
                    title: '般若',
                    image: require('../../assets/cards/16.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '最近出会った人や仲良くなった人の中に、本来の活動目的とは違った下心で近づいて来る人がいるようです。どんなに人当たりが良くても、自分にとって納得できない話題や興味のないことに関しては一貫してNOと言う強さを持ってください。'
                },
                {
                    no: 17,
                    title: '雷',
                    image: require('../../assets/cards/17.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '思いがけない誤解や勘違いから、貴方と距離を取る人が現れるかもしれません。しかし、それは一時的なこと。時間が経つにつれて誤解は解けて以前よりも近しい良き理解者としての関係性を築くことができるでしょう。'
                },
                {
                    no: 18,
                    title: '望月',
                    image: require('../../assets/cards/18.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の中にあった自己否定感や自信のなさが、今ここに至って解消されてきています。貴方本来の輝きや美しさを表現する準備が整ったと言えそうです。自分の心に満月の美しい真円をイメージし安心して物事に取り組んでみてください。'
                },
                {
                    no: 19,
                    title: '闇夜',
                    image: require('../../assets/cards/19.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '人間関係において、相手に対する疑心暗鬼や疑いの気持ちが湧き上がって来るかもしれません。しかし、それは不確かな情報や空気によるもので決して事実ではありません。貴方はいつも通り堂々としていれば良いのです。'
                },
                {
                    no: 20,
                    title: '祭',
                    image: require('../../assets/cards/20.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '信頼できる仲間、ともに学んだり楽しめる友人関係に恵まれ充実しそうな時期。自分自身が楽しんで興味を持てるような趣味や学びを見つけると世代を超えてよい友人関係に恵まれます。'
                },
                {
                    no: 21,
                    title: '出家',
                    image: require('../../assets/cards/21.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '人間関係では誰でも、学びを終えると自然に離れる理があります。相手との関係において何かしら「違う」と感じるなら、それはもう卒業する人間関係かもしれません。我慢や余計な気遣いをせず、相手との距離を置く勇気を持ちましょう。'
                },
                {
                    no: 22,
                    title: '極楽浄土',
                    image: require('../../assets/cards/22.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '極楽浄土とは自ら作るもの、自分の人間関係は自ら構築するものだとこのカードは伝えています。人との関係性において、自分を蔑ろにしなければならない環境や関係は極楽には必要のないもの。貴方が喜びと平和な心に満ちる関係性のみ大切に育んでください。'
                },
            ]
            this.saveLocalStorage();
        }
    }
};

// LoveAdviceCardsAPI.init();
export default LoveAdviceCardsAPI;
