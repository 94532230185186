/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import AsyncStorage from '@react-native-async-storage/async-storage';

import ErrorCodes from './ErrorCodes';
import Entities from "../utils/Entities";
import Constants from "../constants/Constants";

const LoveAdviceCardsAPI = {

    cards: null,

    /**
     *
     * @param code
     * @returns {{success: boolean}}
     */
    get(no){
        var result = {success: true, data: null};

        if(Entities.isEmpty(this.cards)){
            this.init();
        }

        if(Entities.isEmpty(no)){
            if(this.cards.length != 0){
                result.data = this.cards[0];
            }
        }else {
            result.data = this.cards.find(card => card.no === no);
        }

        if(Entities.isEmpty(result.data)){
            result.success = false;
            result.error = ErrorCodes.ERROR_CARD_NOT_FOUND;
        }

        return result;
    },

    saveLocalStorage() {
        // this.saveCards();
    },

    loadLocalStorage() {
        // this.cards =  this.loadCards();
    },

    async saveCards() {
        try {
            await AsyncStorage.setItem('cards', JSON.stringify(this.cards));
        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
    },

    async loadCards() {
        let guidebook = '';
        try {
            guidebook = await AsyncStorage.getItem('cards') || 'none';
            return JSON.parse(guidebook);

        } catch (error) {
            // Error retrieving data
            console.log(error.message);
        }
        return null;
    },

    init(){
        this.loadLocalStorage();

        if (Entities.isEmpty(this.cards)){

            this.cards = [
                {
                    no: 1,
                    title: '風流人',
                    image: require('../../assets/cards/01.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方のアイディアや独創性は、他の人にはない素晴らしい才能です。貴方らしさが活かせる仕事は沢山あるので、自分をもっと積極的に周囲へアピールしてみましょう。独立や起業も向いています。'
                },
                {
                    no: 2,
                    title: '陰陽師',
                    image: require('../../assets/cards/02.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '元々が勤勉で真面目な貴方。仕事においては上司や部下からの信頼も厚く、任された仕事では期待以上の結果を出すことでしょう。実力は確かなので、時には遊び心を持って仕事に取り組んでみるのも良さそう。貴方の提案ならすんなりと受け入れてもらえそうです。'
                },
                {
                    no: 3,
                    title: '斎宮',
                    image: require('../../assets/cards/03.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '感受性と洞察力に優れ、とても繊細な感性が冴えているとき。目には見えない人の心を扱う心理学や、スピリチュアルな分野について見聞を広げてみましょう。それらの分野を仕事にするのも良いですが、現在の仕事や生活に生かすとリラックスできる時間が増えて喜びを感じることが増えそうです。'
                },
                {
                    no: 4,
                    title: '皇后',
                    image: require('../../assets/cards/04.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '本物志向が高まっているとき。仕事においても妥協を許さず、自分にとっての最大限の努力を欠かさないので報酬もそれに見合ったものがついて来るはず。経費は出し惜しみせずに思い切って良いものや高級品に投資すると結果的に自分に良い形で戻って来るでしょう。'
                },
                {
                    no: 5,
                    title: '帝',
                    image: require('../../assets/cards/05.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '組織のリーダーや責任者など、人をまとめたり上に立つことが増えそうな予感。たとえ不安があっても、実は「自分に向いている」と感じることが沢山あるので積極的に引き受けてみること。貴方だからできることや貴方だから信頼してくれる人達に恵まれることでしょう。'
                },
                {
                    no: 6,
                    title: '大阿闍梨',
                    image: require('../../assets/cards/06.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '他人からのアドバイスや忠告をいつも以上に聞くように心掛けましょう。そのアドバイスによって救われることや助かることがありそうです。また、アドバイスをきっかけに貴方にとっての有益で価値のある情報も舞い込んでくる可能性が高いです。'
                },
                {
                    no: 7,
                    title: '戀人',
                    image: require('../../assets/cards/07.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の仕事ぶりを密かに見つめている人がいます。仕事ぶりから貴方の性格や態度を見て、貴方の評価が高まっているようです。周囲からの評価を気にする必要はありませんが、怠惰にならず自分の納得できる仕事をすることが肝要です。'
                },
                {
                    no: 8,
                    title: '車',
                    image: require('../../assets/cards/08.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '室内での仕事だけではなく、出張などで遠出することによって良い運気やチャンスを取り入れられる様子。移動時の車や電車など乗り物の中でふとした瞬間に浮かぶアイディアをメモしておきましょう。その後に繋がるきっかけが隠れていそうです。'
                },
                {
                    no: 9,
                    title: '武士',
                    image: require('../../assets/cards/09.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: 'たとえ迅速さや素早さを求められる仕事であったとしても、細やかな気配りや繊細な作業が功を奏す時期。落ち着いて見直しをすることや、丁寧な作業を心掛けて仕事へ取り組むようにしましょう。その心掛けが大難を小難に、無難に変えてくれます。'
                },
                {
                    no: 10,
                    title: '隠者',
                    image: require('../../assets/cards/10.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '貴方の文章表現が求められています。自分の感じたことや気づいたことを、分かりやすく具体的に文章に表すことを心掛けてみてください。意外なところに自分の得意分野が見つかりそうです。'
                },
                {
                    no: 11,
                    title: '四季',
                    image: require('../../assets/cards/11.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '仕事の配置や人間関係に変化が起きそうな時。周囲の状況や環境が変わっても、貴方自身が大きく変わることはないので、落ち着いて物事の変化を見守る心持ちでいましょう。貴方の仕事もやりやすくなるはずです。'
                },
                {
                    no: 12,
                    title: '勧善懲悪',
                    image: require('../../assets/cards/12.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '職場における不正や、今まで見逃してきたような良くない習慣が表に現れて評価が下されそうな時期。この流れ自体は悪いものではないので、貴方自身が当事者でないなら成り行きを見守っていましょう。職場環境が改善される第一歩です。'
                },
                {
                    no: 13,
                    title: '流刑者',
                    image: require('../../assets/cards/13.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '自分の努力や頑張りが認められないと寂しさや悔しさを感じるかもしれませんが、貴方の仕事ぶりを見てくれている人は必ずいます。自分を責めたり孤独感に苛まれることなく、気分転換をして楽しめることに力を注ぎましょう。'
                },
                {
                    no: 14,
                    title: '閻魔',
                    image: require('../../assets/cards/14.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '任されていた仕事や、職場での立場などが一段落して新しいステップアップの時期を迎えています。転職や異動にも良いタイミング。現状を物足りないと感じるなら、新しいステージを目指して動いてみましょう。'
                },
                {
                    no: 15,
                    title: '宴',
                    image: require('../../assets/cards/15.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '自然の中での仕事に縁のある時期。転職するならアウトドアや植物に関係するものをピックアップしてみたり、仕事環境に観葉植物や花を置くのも良いでしょう。たまには公園やカフェテラスで仕事をしてみるのも吉。仕事のアイディアが浮かんできそうです。'
                },
                {
                    no: 16,
                    title: '般若',
                    image: require('../../assets/cards/16.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '他の人と比べたり周囲の評価が気になって、仕事へ取り組む気持ちがネガティブになったりやる気が失われてしまいそうな予感。そんな時には、少し熱めのお風呂に粗塩を入れゆっくり浸かってみましょう。身体の巡りを整えることで気持ちが落ち着いてきます。'
                },
                {
                    no: 17,
                    title: '雷',
                    image: require('../../assets/cards/17.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '突然の人事異動や環境の刷新など、職場で思いがけない変化を目の当たりにするかもしれません。突然すぎてパニックになるかもしれませんが、変化自体は今後良くなるための再構築に必要なもの。決して悪い方向へ向かうものではないので安心して嵐が過ぎるのを待ちましょう。'
                },
                {
                    no: 18,
                    title: '望月',
                    image: require('../../assets/cards/18.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '昇進や昇給など、自分の願っていた待遇が近づいている気配です。それまでの努力や功績が認められた証拠でもあるので、充分に喜んで自分を讃え褒めてあげましょう。自分の価値を認め、自分を高めることに努めましょう。'
                },
                {
                    no: 19,
                    title: '闇夜',
                    image: require('../../assets/cards/19.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '白黒はっきりさせることだけが良い仕事ではない。このカードはそんなメッセージを伝えています。曖昧さ、グレーゾーンと聞くとあまり良くない印象を抱くかもしれませんが、線引きの曖昧さがあるからこそ認められるものや助けられることもあるのです。多様な価値観を認めるきっかけにもなりそうです。'
                },
                {
                    no: 20,
                    title: '祭',
                    image: require('../../assets/cards/20.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '子どものように無邪気に楽しくワクワクする気持ちが良い仕事や結果を生むとこのカードは伝えています。ともすると義務感や責任感で担うことの多い仕事の場面ですが、そのような環境の中でもワクワクする気持ちや楽しむ気持ちを見出すように心掛けてみましょう。'
                },
                {
                    no: 21,
                    title: '出家',
                    image: require('../../assets/cards/21.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '今の仕事環境や、立場から立ち去るべき時期が来たとこのカードは伝えています。たとえ後ろ髪を引かれるような気持ちがあっても、今は一度そこから離れることが最善のようです。もし、今後本当に戻るべき場であるなら必ず引き戻されるので心配はいりません。'
                },
                {
                    no: 22,
                    title: '極楽浄土',
                    image: require('../../assets/cards/22.jpg'),
                    position: Constants.CardPosition.Upright,
                    message: '一通りのサイクルを経て、貴方には仕事に対する必要な技術や能力が備わったようです。これからはその力を生かし、後輩や後進の指導に力を入れること、育てることに意識を向けるようにとこのカードは言っています。他者を育てることで貴方の人としての器が大きくなっていきます。'
                },
            ]
            this.saveLocalStorage();
        }
    }
};

// LoveAdviceCardsAPI.init();
export default LoveAdviceCardsAPI;
