/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { StatusBar } from 'expo-status-bar';
import { ImageBackground, Dimensions, StyleSheet, View, Text, Image} from 'react-native';
import { NativeBaseProvider } from "native-base";

const {width} = Dimensions.get('window');

import Layout from "./constants/Layout";
import ScreenHeader from "./components/ScreenHeader";
import ActionButton from "./components/ActionButton";

const message = '魂の自由人セラピスト美鴨\n' +
    'HealingRoom★美鴨の癒し★〜小波の詩〜主宰\n' +
    '様々なヒーリングやセラピー、占いを用いて「心・身体・魂から自由に軽やかに」生きることをモットーに個人セッションやセミナーを開催。\n過去の総クライアント数は延べ１０００人以上。\n' +
    '元々好きだった古典文学や平安文化を基に、日本独自の雰囲気を持つ平安絵巻タロットを考案。\n';

export default class SupervisorScreen extends React.PureComponent {

    render() {
        return (
            <NativeBaseProvider>
                <StatusBar hidden/>
                <ImageBackground source={require('../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={'監修者について'} navigation={this.props.navigation}/>
                    <View style={styles.middle}>
                        <Image source={require('../assets/images/panel-supervisor.png')}
                               style={styles.panel}/>
                        <Text style={styles.message}>{message}</Text>
                    </View>
                    <View style={styles.bottom}>
                        <ActionButton navigation={this.props.navigation} light title={'トップへ戻る'} action={'Top'}/>
                    </View>
                </ImageBackground>
            </NativeBaseProvider>
        );
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        position: 'absolute',

        width:  Layout.screen.width,
        height: Layout.screen.height*.8,

        bottom: Layout.screen.height*.1
    },
    panel: {
        width:  Layout.screen.width*.846,
        height: Layout.screen.width*.846*1.535,
    },
    message: {
        position: 'absolute',

        fontFamily: 'YuMincho',
        fontSize: width*.4*.33*.28,

        color: '#70615A',

        width:  Layout.screen.width*.72,
        height: Layout.screen.width*.78*1.535,

        lineHeight: width*.4*.33*.5,
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height*.1,

        position: 'absolute',
        bottom: 0
    }
});