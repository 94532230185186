/**
 *
 *  Copyright 2022 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { NavigationEvents } from "react-navigation";
import { StatusBar } from 'expo-status-bar';
import { ResizeMode, Video } from 'expo-av';
import { NativeBaseProvider } from "native-base";

import {ImageBackground, StyleSheet, View, Image, TouchableOpacity} from 'react-native';

import Layout from "./../constants/Layout";

import ScreenHeader from "../components/ScreenHeader";
import ScreenHint from "../components/ScreenHint";
import ActionButton from "../components/ActionButton";
import Constants from "../constants/Constants";
import Entities from "../utils/Entities";

const states = new Map();
states.set(Constants.Loop.Day,      {start: 0, end:2250});
states.set(Constants.Loop.Evening,  {start: 2270, end:5170});
states.set(Constants.Loop.Night,    {start: 5200, end:8190});

export default class TodayFortuneTellingShuffle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {preloaded: false};

        this.loop = Constants.Loop.Day;
        this.state = states.get(Constants.Loop.Day);
    }

    render() {
        return (
            <NativeBaseProvider>
                <NavigationEvents onWillBlur={() => {
                    if(!Entities.isEmpty(this.video)){
                        this.video.stopAsync();
                    }
                }}/>
                <StatusBar hidden/>
                <ImageBackground source={require('../../assets/images/bg-screen.png')} resizeMode='stretch'
                                 style={{width: '100%', height: '100%'}}>

                    <ScreenHeader title={'今日のメッセージ'} navigation={this.props.navigation} left={false}/>
                    <View style={styles.middle}>
                        <ScreenHint text={'下の画面をタップしてください'}/>

                        <TouchableOpacity style={styles.action} activeOpacity={.8} onPress={() => {
                            this.switchAnimation();
                        }}>
                            {this.renderImage()}
                            {this.renderVideo()}
                        </TouchableOpacity>
                    </View>
                    {
                        this.state.preloaded &&
                        <View style={styles.bottom}>
                            <ActionButton navigation={this.props.navigation} title={'シャッフル終了'} action={'TodayProcess'}/>
                        </View>
                    }
                </ImageBackground>
            </NativeBaseProvider>
        );
    }

    renderVideo(){
        const scope = this;
        return (
            <Video
                resizeMode={ResizeMode.CONTAIN}
                ref={(video) => {
                    this.video = video;

                    if(!Entities.isEmpty(video)){
                        this.video.pauseAsync().then(() => {
                            this.video.playFromPositionAsync(0);
                        });
                    }
                }}
                onLoad={() => {
                    setTimeout(() => { scope.setState({preloaded: true}) }, 50);
                    // this.setState({preloaded: true})
                }}
                style={[styles.animation, {position:'absolute', display: scope.state.preloaded?'flex':'none'}]}
                source= {require('../../assets/animations/animation.mp4')}
                isLooping={true}
                onPlaybackStatusUpdate={(status) => {
                    if(Entities.isDefined(status.positionMillis) && status.isPlaying){
                        console.log(': ' + status.positionMillis + ' ms');

                        if(status.positionMillis > this.state.end){
                            this.video.playFromPositionAsync(this.state.start);
                        }
                    }
                }}
            />
        );
    }

    renderImage(){
        return (
            <Image style={[styles.animation, {resizeMode: 'contain'}]}  source={require('../../assets/animations/day.jpg')}/>
        );
    }

    switchAnimation(){
        switch (this.loop){
            case Constants.Loop.Day:
                this.loop = Constants.Loop.Evening;
                break;
            case Constants.Loop.Evening:
                this.loop = Constants.Loop.Night;
                break;
            case Constants.Loop.Night:
                this.loop = Constants.Loop.Day;
                break;
        }

        console.log('Switch video: ' + this.loop);
        this.state = states.get(this.loop);

        this.video.playFromPositionAsync(this.state.start);
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#f2f2f2'
    },
    middle: {
        justifyContent: 'center',
        alignItems: 'center',

        width: Layout.screen.width,
        height: Layout.screen.height*.8,

        position: 'absolute',
        bottom: Layout.screen.height/16
    },
    action: {
        position: 'absolute',
        top: Layout.screen.height*.15,

        width: Layout.screen.width*.837,
        height: Layout.screen.width*.837*1.231
    },
    animation: {
        width: Layout.screen.width*.837,
        height: Layout.screen.width*.837 * 1.23
    },
    bottom: {
        width:  Layout.screen.width,
        height: Layout.screen.height/10,

        position: 'absolute',
        bottom: 0
    }
});
