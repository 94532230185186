/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import {Dimensions, StyleSheet} from "react-native";

const {width, height} = Dimensions.get('screen');

export default {

    screen : StyleSheet.create({
        hint: {
            position: 'absolute',
            top: 0,

            fontFamily: 'YuMincho',
            fontSize: 14,
            color: '#70615A',

            textAlign: 'center',
            width: width,
            height: height*.05
        },
        hintBackground: {
            marginLeft: 20,
            width: width-40,
            height: (width-40)/5.94,

            resizeMode: 'stretch',
        },
        flipCard: {
            width: width*.1,
            height: width*.172
        }
    }),
    common : StyleSheet.create({
        Container: {
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#4a4a4a',
        },
        Center: {
            alignItems: 'center',
            justifyContent: 'center'
        }
    })
}