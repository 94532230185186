/**
 *
 *  Copyright 2021 (c) <company>
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of <company> and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to <company>
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from <company>
 *
 */
import React from "react";
import { Dimensions, StyleSheet, TouchableOpacity, Image} from 'react-native';
import {HStack, Center, Heading} from "native-base";

import Layout from "./../constants/Layout";
import {NavigationActions} from "react-navigation";
import Entities from "../utils/Entities";

import Constants from "../constants/Constants";

const {width} = Dimensions.get('window');

export default class ScreenHeader extends React.PureComponent {

    /**
     *
     */
    render() {
        return (
            <HStack style={styles.top}>
                <Center style={{width: '18%',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-end'
                }}>
                    <TouchableOpacity style={{display:this.showButton(this.props.left)}} activeOpacity={.8} onPress={() => {

                        if(Entities.isDefined(this.props.leftAction)){
                            const navigationAction = NavigationActions.navigate({
                                routeName: this.props.leftAction,
                                params: {
                                    reset: false
                                }
                            });

                            if(!Entities.isEmpty(this.props.navigation)){
                                return this.props.navigation.dispatch(navigationAction);
                            }
                        }
                        return this.navigateToScreen('Top');
                    }}>
                        <Image style={styles.btnHeader} source={require('../../assets/images/btn-back.png')}/>
                    </TouchableOpacity>
                </Center>
                <Center style={{width: '64%'}}>
                    <Heading style={styles.title}>{this.props.title}</Heading>
                </Center>
                <Center style={{width: '18%',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'flex-start'
                }}>
                    <TouchableOpacity style={{display:this.showButton(this.props.right)}} activeOpacity={.8} onPress={() => {
                        this.props.navigation.openDrawer();
                    }}>
                        <Image style={styles.btnHeader} source={require('../../assets/images/btn-menu.png')}/>
                    </TouchableOpacity>
                </Center>
            </HStack>
        );
    }

    showButton(value){
        if(!Entities.isDefined(value))
            return 'flex';

        return value?'flex':'none';
    }

    /**
     *
     */
    navigateToScreen(name){
        const navigationAction = NavigationActions.navigate({
            routeName: name
        });
        this.props.navigation.dispatch(navigationAction);
    }
}

let styles = StyleSheet.create({
    btnHeader: {
        width: width/6,
        height: width/6,

        resizeMode: 'stretch',
    },
    top: {
        borderBottomWidth: 0,
        backgroundColor: 'transparent',

        width:  Layout.screen.width,
        height: Layout.screen.height*.1,
        top: Layout.screen.height*.032
    },
    title: {
        fontFamily: 'YuMincho',
        fontSize: Layout.screen.height*.1*.24,
        color: '#70615A',

        width: width *.68,

        alignItems: 'center',
        justifyContent: 'center',

        textAlign: 'center',

        top: Layout.screen.height*.02,
        height: Layout.screen.height*.04
    }
});

const viewport = Entities.getViewPort(Layout.screen.width, Layout.screen.height);

switch(viewport){
    case Constants.ViewPort.Tiny:
    case Constants.ViewPort.Small:
        break;
    case Constants.ViewPort.High:
    case Constants.ViewPort.Full:
        styles = StyleSheet.create({
            btnHeader: {
                width: width/6,
                height: width/6,

                resizeMode: 'stretch',
            },
            top: {
                borderBottomWidth: 0,
                backgroundColor: 'transparent',

                width:  Layout.screen.width,
                height: Layout.screen.height*.1,
                top: Layout.screen.height*.045
            },
            title: {
                fontFamily: 'YuMincho',
                fontSize: Layout.screen.height*.1*.21,
                width: width *.66,

                alignItems: 'center',
                justifyContent: 'center',

                textAlign: 'center',

                top: Layout.screen.height*.02,
                height: Layout.screen.height*.04
            }
        });
}


